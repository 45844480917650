import React, {Fragment, useContext} from "react";
import Loader from "../assets/imgs/template/loading.gif";
import LogoImg from "../assets/imgs/logos/logo.png";
import {Link, useNavigate} from "react-router-dom";
import StateContext from "../../Context/StateContext";
import axios from "axios";
import DispatchContext from "../../Context/DispatchContext";


function HeaderIndex() {
    const navigate = useNavigate();
    const GlobalState = useContext(StateContext);
    const GlobalDispatch = useContext(DispatchContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    return (
        <Fragment>
            <header className="header sticky-bar">
              <div className="container">
                <div className="main-header">
                  <div className="header-left">
                    <div className="header-logo">
                        <a className="d-flex" href="/">
                            <img alt="jobBox" src={LogoImg} style={{width: "450px", height: "150px", borderRadius:"25px"}} />
                        </a>
                    </div>
                  </div>
                  <div className="header-nav">
                    <nav className="nav-main-menu">
                      <ul className="main-menu">
                        <li>
                          <a className="active" href="/">Accueil</a>
                        </li>
                        {/* <li className="">
                          <a href="/about">A Propos</a>
                        </li> */}
                      </ul>
                    </nav>
                    <div className="burger-icon burger-icon-white"><span className="burger-icon-top"></span><span className="burger-icon-mid"></span><span className="burger-icon-bottom"></span></div>
                  </div>
                </div>
              </div>
            </header>
        </Fragment>
    )

}
export default HeaderIndex;