import React, {Fragment, useContext, useEffect, useState} from "react";
import DashBordHeader from "./_partials/DashBordHeader";
import Header from "./_partials/Header";
import Form from 'react-bootstrap/Form';
import { PhoneInput } from 'react-international-phone';
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import DispatchContext from "../Context/DispatchContext";
import StateContext from "../Context/StateContext";
import {useImmerReducer} from "use-immer";

import BaseUrl from "./config/baseUrl";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";

const url = BaseUrl()

function Dashboard() {
  const navigate = useNavigate()
  const GlobalDispatch = useContext(DispatchContext)
  const GlobalState = useContext(StateContext)
  const [phone, setPhone] = useState('');
  const [errors, setErrorM] = useState({});
  const {AnnonceID} = useParams();
  const [user,setUser] = useState([]);
  const [dataIsloading, setDataIsLoading] = useState(true)
  const [annonceEdit,setAnnonceEdit] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const CatOption = [
        {
            value: '',
            label: '',
        },
        {
            value: 'DECES',
            label: 'DECES',
        },
        {
            value: 'IN MEMORIAM',
            label: 'IN MEMORIAM',
        },
    ]
  const TypeAnnonce  = [
        {
            value: '',
            label: '',
        },
        {
            value: 'PREMIUM',
            label: 'PREMIUM',
        },
        {
            value: 'PRIVILEGE',
            label: 'PRIVILEGE',
        },
        {
            value: 'GRATUITE',
            label: 'GRATUITE',
        },
    ]
  const [annonceEditData,setAnnonceEditData] = useState({
        'user': "",
        'type_annonce': "",
        'categorie': "",
        'titre': "",
        'date': "",
        'image1': "",
        'image2': "",
        'image3': "",
        'image4': "",
        'image5': "",
        'faire_part': "",
        'programme': "",
  });
  const [errorNameCoop,setErrorNameCoop] = useState('');
  const [annonnceList,setAnnonceList] = useState([]);

  // const handleChangeProd=(event)=>{
  //     setProducteurData({
  //       ...producteurData,
  //       [event.target.name]:event.target.value
  //     });
  // }
  const handleChangeEditAnnonce=(event)=>{
      setAnnonceEditData({
        ...annonceEditData,
        [event.target.name]:event.target.value
      });
    }
  const openModalUpdateAnnonce=(id)=>{
        // console.log("Annonce Id:", id);
        window.$(`#ModalUpdateAnnonce-${id}`).modal("show");
        axios.get(url+'/annonces/?id='+id).then((resp)=>{
          setAnnonceEdit(resp.data[0]);
          console.log(resp.data[0])
          setAnnonceEditData({
            // "code":resp.data.results[0].code,
            // "section_edit":resp.data.results[0].section?.id,
            "titre":resp.data[0].titre,
            "categorie":resp.data[0].categorie,
            "type_annonce":resp.data[0].type_annonce,
            "date":resp.data[0].date,
            "faire_part":resp.data[0].faire_part,
            "programme":resp.data[0].programme
          })
        });
      }

  const submitAnnonceedit=()=>{
        //setErrorM(Validation(producteurEditData));
        if(annonceEditData.titre !="" && annonceEditData.categorie !="" && annonceEditData.type_annonce!="" )
        {

          const _formData = new FormData();
          _formData.append('titre',annonceEditData.titre);
          _formData.append('categorie',annonceEditData.categorie);
          _formData.append('type_annonce',annonceEditData.type_annonce);
          _formData.append('date',annonceEditData.date);
          _formData.append('faire_pare',annonceEditData.faire_part);
          _formData.append('programme',annonceEditData.programme);
          _formData.append('user', GlobalState.userId);
          //console.log(producteurEditData.photo_edit)
          // if(producteurEditData.photo_edit != undefined
          //console.log(_formData);

            Swal.fire({
            title: 'Modification en cours...',
            html: 'Veillez patientez...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            },

          });

          try {
            axios.post(url+'/annonce/update/',_formData).then((resp)=>{
              Swal.close()
              if(resp.data.bool)
                {
                window.$(`#ModalUpdateAnnonce-${annonceEdit.id}`).modal('hide');
                Swal.fire({
                  title: 'FELICITATION !',
                  html: " Modification effectuée avec succès ! ",
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'OK'
                })

                setAnnonceEditData({
                    'type_annonce': "",
                    'categorie': "",
                    'titre': "",
                    'date': "",
                    'faire_part': "",
                    'programme': "",
                })

              setAnnonceEdit([]);
              }else{
                Swal.fire({
                  // position: 'top-end',
                  icon: 'error',
                  title: "Oops ! une erreur s'est produite.",
                  showConfirmButton: false,
                  timer: 3000,
                  toast:true,
                  position:'top-right',
                  timerProgressBar:true
                });
              }
            })
          } catch (error) {
            console.log(error);
          }
        }
      }


  // useEffect(() => {
  //   try {
  //     axios
  //         .get(url+ `/dashboard/?user=${GlobalState.userId}`)
  //         .then((resp) => {
  //         setAnnonceList(resp.data)
  //         console.log(resp.data)
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // },[]);

  useEffect(() => {
        async function GetUserAnnonce() {
            try {
                const response = await axios
                    .get(url+ `/dashboard/?userID=${GlobalState.userId}`)
                    // .get(url+`/dashboard/${GlobalState.userId}/`);
                    setAnnonceList(response.data);
                    // dispatch({type: 'catchProfileInfo', profileObject: response.data})
            }catch (e) {
                console.log(e.response);
            }
        }
        GetUserAnnonce();
    }, []);

  useEffect(() => {
        const source = axios.CancelToken.source();
        async function GetUserAnnonce() {
            try{
                const response = await axios
                    .get(
                        url+ `/dashboard/?userID=${GlobalState.userId}`,
                    );
                setAnnonceList(response.data);
                setDataIsLoading(false)
            } catch (error) {
                console.log(error.response);
            }
        }
        GetUserAnnonce();
        return () => {
            source.cancel();
        };
    }, []);

  const initialState = {
       userProfile: {
          contact2: "",
          adresse: "",
          avatar: "",
       },
    };

    const TypePiece  = [
        {
            value: '',
            label: '',
        },
        {
            value: 'ATTESTATION',
            label: 'ATTESTATION',
        },
        {
            value: 'CNI',
            label: 'CNI',
        },
        {
            value: 'PASSEPORT',
            label: 'PASSEPORT',
        },
        {
            value: 'CARTE DE SEJOUR',
            label: 'CARTE DE SEJOUR',
        },
        {
            value: 'CARTE CONSULAIRE',
            label: 'CARTE CONSULAIRE',
        },
    ]

    function ReducerFunction (draft, action) {
        switch(action.type) {
          case "catchUserProfileInfo":
          draft.userProfile.contact2 = action.profileObject.contact2;
          draft.userProfile.adresse = action.profileObject.adresse;
          draft.userProfile.avatar = action.profileObject.avatar;
        }
    }

    const [state, dispatch] =useImmerReducer(ReducerFunction, initialState)

  useEffect(() => {
        async function GetProfileInfo() {
            try {
                const response = await axios
                    .get(url+`/profiles/${GlobalState.userId}/`);
                    // console.log(response.data);
                    dispatch({type: 'catchProfileInfo', profileObject: response.data})
            }catch (e) {
                console.log(e.response);
            }
        }
        GetProfileInfo();
    }, []);

    return(
        <Fragment>
            <Header />
            <main className="main">
              <section className="section-box-2">
                <div className="container">
                  <div className="banner-hero banner-image-single"><img src="assets/imgs/page/candidates/img.png" alt="jobbox" /><a className="btn-editor" href="#"></a></div>
                  <div className="box-company-profile">
                    <div className="image-compay"><img src="assets/imgs/page/candidates/candidate-profile.png" alt="jobbox" /></div>
                    <div className="row mt-10">
                      <div className="col-lg-8 col-md-12">
                        <h5 className="f-18">Bienvenu {GlobalState.userId} <span className="card-location font-regular ml-20">Cocody Angré</span></h5>
                        {/*<p className="mt-0 font-md color-text-paragraph-2 mb-15">UI/UX Designer. Front end Developer</p>*/}
                      </div>
                      <div className="col-lg-4 col-md-12 text-lg-end"> <a className="btn btn-apply btn-apply-big" href="/pricing">Créer une  Annonce</a></div>
                    </div>
                  </div>
                  <div className="border-bottom pt-10 pb-10"></div>
                </div>
              </section>
              <section className="section-box mt-50">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-12">
                      <div className="box-nav-tabs nav-tavs-profile mb-5">
                        <ul className="nav" role="tablist">
                          <li><a className="btn btn-border recruitment-icon mb-20 active" href="#tab-my-jobs" data-bs-toggle="tab" role="tab" aria-controls="tab-my-jobs" aria-selected="true">Mes Annonces</a></li>
                          <li><a className="btn btn-border aboutus-icon mb-20" href="#tab-my-profile" data-bs-toggle="tab" role="tab" aria-controls="tab-my-profile" aria-selected="false">Profile</a></li>
                          {/*<li><a className="btn btn-border people-icon mb-20" href="#tab-saved-jobs" data-bs-toggle="tab" role="tab" aria-controls="tab-saved-jobs" aria-selected="false">Saved Jobs</a></li>*/}
                        </ul>
                        <div className="border-bottom pt-10 pb-10"></div>
                        {/*<div className="mt-20 mb-20"><a className="link-red" href="#">Delete Account</a></div>*/}
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-8 col-sm-12 col-12 mb-50">
                      <div className="content-single">
                          <div className="tab-content">
                              <div className="tab-pane fade" id="tab-my-profile" role="tabpanel"
                                   aria-labelledby="tab-my-profile">
                                  <h3 className="mt-0 mb-15 color-brand-1">Mon Compte</h3><a
                                  className="font-md color-text-paragraph-2" href="#"></a>
                                  <div className="mt-35 mb-40 box-info-profie">
                                      <div className="image-profile">
                                          <img src="assets/imgs/page/candidates/candidate-profile.png" alt="jobbox"/>
                                      </div>
                                      <a className="btn btn-apply">Modifier Avatar</a>
                                      <div className="border-bottom pt-10 pb-10"></div>
                                      {/*<a className="btn btn-link">Delete</a>*/}
                                  </div>
                                  <div className="row form-contact">
                                      <div className="col-lg-12 col-md-12">
                                          <div className="form-group">
                                              <label className="font-sm color-text mb-10">Nom Utilisateur <span
                                                  className="text-danger">*</span></label>
                                              <input className="form-control"
                                                     style={{padding: "10px", borderRadius: "15px"}} type="text"
                                                     value="Steven Job"/>
                                          </div>
                                          <div className="form-group">
                                              <label className="font-sm color-text mb-10">Nom et Prénoms <span
                                                  className="text-danger">*</span></label>
                                              <input className="form-control" type="text" value="Steven Job"/>
                                          </div>
                                          <div className="form-group">
                                              <label className="font-sm color-text mb-10">Email <span
                                                  className="text-danger">*</span></label>
                                              <input className="form-control"
                                                     style={{padding: "10px", borderRadius: "15px"}} type="text"
                                                     value="stevenjob@gmail.com"/>
                                          </div>
                                          <div className="row">
                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label className="font-sm color-text mb-10">Contact 1 <span
                                                          className="text-danger">*</span></label>
                                                      <PhoneInput
                                                          id="contact1"
                                                          style={{width: "100%", borderRadius: "15px", padding: "10px"}}
                                                          defaultCountry="ci"
                                                          value={phone}
                                                          onChange={(phone) => setPhone(phone)}
                                                          required
                                                      />
                                                      {/*<input className="form-control" style={{padding: "10px", borderRadius: "15px"}} type="text" value="01 - 234 567 89" />*/}
                                                  </div>
                                              </div>
                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label className="font-sm color-text mb-10">Contact 2</label>
                                                      <PhoneInput
                                                          id="contact1"
                                                          style={{width: "100%", borderRadius: "15px", padding: "10px"}}
                                                          defaultCountry="ci"
                                                          value={phone}
                                                          onChange={(phone) => setPhone(phone)}
                                                          required
                                                      />
                                                      {/*<input className="form-control" style={{padding: "10px", borderRadius: "15px"}} type="text" value="01 - 234 567 89" />*/}
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="border-bottom pt-10 pb-10 mb-30"></div>
                                          {/*<h6 className="color-orange mb-20">Modifier Mot de Passe</h6>*/}
                                          <div className="row">
                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label className="font-sm color-text mb-10">Adresse <span
                                                          className="text-danger">*</span></label>
                                                      <input className="form-control"
                                                             style={{padding: "10px", borderRadius: "15px"}} type="text"
                                                             value=""/>
                                                  </div>
                                              </div>
                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label className="font-sm color-text mb-10">Type Pièce<span
                                                          className="text-danger">*</span></label>
                                                      <Form.Select
                                                          style={{padding: "10px", borderRadius: "15px"}}
                                                          aria-label=""
                                                          // value={state.typeValue}
                                                          // onChange={(e)=>dispatch({
                                                          //     type:'catchTypeChange',
                                                          //     typeChosen: e.target.value
                                                          // })}
                                                      >
                                                          {TypePiece.map((option) => (
                                                              <option key={option.value} value={option.value}>
                                                                  {option.label}
                                                              </option>
                                                          ))}
                                                      </Form.Select>
                                                  </div>
                                              </div>
                                          </div>
                                          {/*<h6 className="color-orange mb-20">Modifier Mot de Passe</h6>*/}
                                          <div className="row">
                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label className="font-sm color-text mb-10">Numéro Pièce <span
                                                          className="text-danger">*</span></label>
                                                      <input className="form-control"
                                                             style={{padding: "10px", borderRadius: "15px"}} type="text"
                                                             value=""/>
                                                  </div>
                                              </div>
                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label className="font-sm color-text mb-10">Charger La Pièce<span
                                                          className="text-danger">*</span></label>
                                                      <div className="add-file-upload">
                                                          <input className="fileupload" className="form-control"
                                                                 style={{padding: "10px", borderRadius: "15px"}}
                                                                 type="file" name="file"/>
                                                      </div>
                                                      {/*<input className="form-control" type="text" style={{padding: "10px", borderRadius: "15px"}} value="123456789" />*/}
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="border-bottom pt-10 pb-10"></div>
                                          <div className="box-button mt-15">
                                              <button className="btn btn-apply-big font-md font-bold"
                                                      style={{borderRadius: "32px"}}>Modifier Profile
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="tab-pane fade show active" id="tab-my-jobs" role="tabpanel"
                                   aria-labelledby="tab-my-jobs">
                                  <h3 className="mt-0 color-brand-1">Mes Annonces</h3>
                                  <div className="row display-list">
                                      {
                                          annonnceList.map((annonce) => (
                                              <div className="col-xl-12 col-12">
                                                  <div className="card-grid-2 hover-up"><span className="flash"></span>
                                                      <div className="row">
                                                          <div className="col-lg-8 col-md-8 col-sm-12">
                                                              <div className="card-grid-2-image-left">
                                                                  <div className="image-box">
                                                                      <img src={annonce.image1}
                                                                           style={{width: "150px", height: "150px"}}
                                                                           alt="jobBox"/>
                                                                  </div>
                                                                  <div className="">
                                                                      {/*<a className="name-job" href="">{annonce.titre}</a>*/}
                                                                      <h4>
                                                                          <a onClick={() => navigate(
                                                                              `/annonces/${annonce.id}`
                                                                          )} href=""> Titre : {annonce.titre}
                                                                          </a>
                                                                      </h4>
                                                                      <span
                                                                          className="location-small">Code : {annonce.code}</span>
                                                                  </div>
                                                                  {/*<p className="font-sm color-text-paragraph mt-10">{annonce.programme.substring(0, 250)}</p>*/}
                                                              </div>
                                                          </div>
                                                          <div
                                                              className="col-lg-4 text-start text-md-end pr-60 col-md-4 col-sm-12">
                                                              <div className="pl-15 mb-15 mt-30">
                                                                  <a className="btn btn-grey-small mr-5"
                                                                     href="#">{annonce.categorie}</a>
                                                                  <a className="btn btn-grey-small mr-5"
                                                                     href="#">{annonce.type_annonce}</a>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="card-block-info" style={{marginTop: "-20px"}}>
                                                          {/*<h4>*/}
                                                          {/*    <a onClick={() =>navigate(*/}
                                                          {/*          `/annonces/${annonce.id}`*/}
                                                          {/*      )} href="">{annonce.titre}*/}
                                                          {/*    </a>*/}
                                                          {/*</h4>*/}
                                                          <div className="mt-5">
                                                              {/*<span className="card-info"> {annonce.user.nom}</span>*/}
                                                              <span
                                                                  className="card-briefcase"> <b>{annonce.code}</b></span>
                                                              <span
                                                                  className="card-time"><span> {annonce.date}</span></span>
                                                          </div>
                                                          <p className="font-sm color-text-paragraph mt-5">{annonce.programme.substring(0, 250)}</p>
                                                          <div className="card-2-bottom mt-20">
                                                              <div className="row">
                                                                  <div className="col-lg-5 col-5">
                                                                      {/*<span className="card-text-price">$500</span>*/}
                                                                      <div
                                                                          onClick={()=>openModalUpdateAnnonce(annonce.id)}
                                                                          className="btn btn-info btn-apply-icon btn-apply hover-up"
                                                                          >Modifier
                                                                      </div>
                                                                      {/*<a className="btn btn-info" data-bs-toggle="#ModalUpdateAnnonce" href="">Modifier</a>*/}
                                                                      {/*<span className="text-muted">/Hour</span>*/}
                                                                  </div>
                                                                  <div className="col-lg-7 col-7 text-end">
                                                                  <div onClick={() => navigate(
                                                                          `/annonces/${annonce.id}`
                                                                      )}
                                                                           className="btn btn-apply-now"
                                                                          // data-bs-toggle="modal"
                                                                           data-bs-target="#ModalApplyJobForm">Détails
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="modal fade"  id={`ModalUpdateAnnonce-${annonce.id}`} tabIndex="-1" aria-hidden="true">
                                                      <div className="modal-dialog modal-lg">
                                                          <div className="modal-content apply-job-form">
                                                              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                                                              <div className="modal-body pl-30 pr-30 pt-50">
                                                                  <div className="text-center">
                                                                      <p className="font-sm text-brand-2 text-danger" style={{fontWeight: "bold"}}>Modifier l'annonce {annonce.code} - {annonce.categorie} de {annonce.titre}</p>
                                                                  </div>
                                                                  <form
                                                                      className="login-register text-start mt-20 pb-30"
                                                                      action="#">
                                                                      <div className="row">
                                                                          <div className="col-md-6">
                                                                              <div className="form-group">
                                                                                  <label className="form-label"
                                                                                         htmlFor="categorie">Type
                                                                                      Annonce <span
                                                                                          className="text-danger">*</span></label>
                                                                                  <Form.Select
                                                                                      style={{
                                                                                          padding: "10px",
                                                                                          borderRadius: "15px"
                                                                                      }}
                                                                                      aria-label=""
                                                                                      name="type_annonce"
                                                                                      onChange={handleChangeEditAnnonce}
                                                                                      value={annonce.type_annonce}
                                                                                  >
                                                                                      {TypeAnnonce.map((option) => (
                                                                                          <option key={option.value}
                                                                                                  value={option.value}>
                                                                                              {option.label}
                                                                                          </option>
                                                                                      ))}
                                                                                  </Form.Select>
                                                                              </div>
                                                                          </div>
                                                                          <div className="col-md-6">
                                                                              <div className="form-group">
                                                                                  <label className="form-label"
                                                                                         htmlFor="categorie">Catégorie <span
                                                                                      className="text-danger">*</span></label>
                                                                                  <Form.Select
                                                                                      style={{
                                                                                          padding: "10px",
                                                                                          borderRadius: "15px"
                                                                                      }}
                                                                                      aria-label=""
                                                                                      name="categorie"
                                                                                      onChange={handleChangeEditAnnonce}
                                                                                      value={annonce.categorie}
                                                                                  >
                                                                                      {CatOption.map((option) => (
                                                                                          <option key={option.value}
                                                                                                  value={option.value}>
                                                                                              {option.label}
                                                                                          </option>
                                                                                      ))}
                                                                                  </Form.Select>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                      <div className="form-group">
                                                                          <label className="form-label"
                                                                                 htmlFor="titre">Code Annonce <span
                                                                              className="text-danger">*</span></label>
                                                                          <input
                                                                              disabled
                                                                              id="code"
                                                                              name="code"
                                                                              style={{
                                                                                  padding: "5px",
                                                                                  borderRadius: "15px",
                                                                                  color: "red",
                                                                                  fontWeight: "bold",
                                                                                  textAlign: "center",
                                                                                  fontSize: "22px"
                                                                              }}
                                                                              type="text"
                                                                              className="form-control"
                                                                              placeholder="Code"
                                                                              required=""
                                                                              onChange={handleChangeEditAnnonce}
                                                                              value={annonce.code}
                                                                          />
                                                                      </div>
                                                                      <div className="form-group">
                                                                          <label className="form-label"
                                                                                 htmlFor="titre">Titre <span
                                                                              className="text-danger">*</span></label>
                                                                          <input
                                                                              id="titre"
                                                                              name="titre"
                                                                              style={{
                                                                                  padding: "5px",
                                                                                  borderRadius: "15px",
                                                                                  color: "red",
                                                                                  fontWeight: "bold"
                                                                              }}
                                                                              type="text"
                                                                              className="form-control"
                                                                              placeholder="Titre"
                                                                              required=""
                                                                              onChange={handleChangeEditAnnonce}
                                                                              value={annonce.titre}
                                                                          />
                                                                      </div>
                                                                      <div className="form-group">
                                                                          <label className="form-label"
                                                                                 htmlFor="date">Date <span
                                                                              className="text-danger">*</span></label>
                                                                          {/*<input*/}
                                                                          {/*    type="date"*/}
                                                                          {/*    name="date"*/}
                                                                          {/*    style={{*/}
                                                                          {/*        width: "100%",*/}
                                                                          {/*        padding: "15px",*/}
                                                                          {/*        borderRadius: "15px"*/}
                                                                          {/*    }}*/}
                                                                          {/*    onChange={handleChangeEditAnnonce}*/}
                                                                          {/*    value={annonce.date}*/}
                                                                          {/*/>*/}
                                                                          <DatePicker className="form-control"
                                                                              // id="date"
                                                                              name="date"
                                                                              style={{
                                                                                  width: "100%",
                                                                                  padding: "15px",
                                                                                  borderRadius: "15px",
                                                                              }}
                                                                              selected={startDate}
                                                                              onChange={handleChangeEditAnnonce}
                                                                              value={annonce.date}
                                                                              locale="fr"
                                                                              dateFormat="yyyy-MM-dd"
                                                                              maxDate={startDate}
                                                                          />
                                                                      </div>
                                                                      <div className="form-group">
                                                                          <label className="form-label"
                                                                                 htmlFor="programme">Faire-part <span
                                                                              className="text-danger">*</span></label>
                                                                          <textarea
                                                                              style={{
                                                                                  padding: "5px",
                                                                                  borderRadius: "15px",
                                                                                  height: "130px"
                                                                              }}
                                                                              className="form-control"
                                                                              placeholder="Faire-part"
                                                                              // id="faire_part"
                                                                              name="faire_part"
                                                                              rows="8"
                                                                              onChange={handleChangeEditAnnonce}
                                                                              value={annonce.faire_part}
                                                                          />
                                                                      </div>

                                                                      <div className="form-group">
                                                                          <label className="form-label"
                                                                                 htmlFor="programme">Programme <span
                                                                              className="text-danger">*</span></label>
                                                                          <textarea
                                                                              style={{
                                                                                  padding: "5px",
                                                                                  borderRadius: "15px",
                                                                                  height: "130px"
                                                                              }}
                                                                              className="form-control"
                                                                              placeholder="Programme"
                                                                              // id="programme"
                                                                              name="programme"
                                                                              rows="8"
                                                                              onChange={handleChangeEditAnnonce}
                                                                              value={annonce.programme}

                                                                          />
                                                                      </div>
                                                                      <div className="form-group">
                                                                          <button
                                                                              onClick={submitAnnonceedit}
                                                                              className="btn btn-default hover-up w-100"
                                                                              type="submit"
                                                                              name="login">Envoyé
                                                                          </button>
                                                                      </div>
                                                                  </form>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          ))
                                      }
                                  </div>
                                  <div className="paginations">
                                      <ul className="pager">
                                          <li><a className="pager-prev" href="#"></a></li>
                                          <li><a className="pager-number" href="#">1</a></li>
                                          <li><a className="pager-number" href="#">2</a></li>
                                          <li><a className="pager-number" href="#">3</a></li>
                                          <li><a className="pager-number" href="#">4</a></li>
                                          <li><a className="pager-number" href="#">5</a></li>
                                          <li><a className="pager-number active" href="#">6</a></li>
                                          <li><a className="pager-number" href="#">7</a></li>
                                          <li><a className="pager-next" href="#"></a></li>
                                      </ul>
                                  </div>
                              </div>
                              {/*<div className="tab-pane fade" id="tab-saved-jobs" role="tabpanel" aria-labelledby="tab-saved-jobs">*/}
                              {/*  <h3 className="mt-0 color-brand-1 mb-50">Saved Jobs</h3>*/}
                              {/*  <div className="row">*/}
                              {/*    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">*/}
                              {/*      <div className="card-grid-2 hover-up">*/}
                              {/*        <div className="card-grid-2-image-left"><span className="flash"></span>*/}
                              {/*          <div className="image-box"><img src="assets/imgs/brands/brand-1.png" alt="jobBox" /></div>*/}
                              {/*          <div className="right-info"><a className="name-job" href="company-details.html">LinkedIn</a><span className="location-small">New York, US</span></div>*/}
                              {/*        </div>*/}
                              {/*        <div className="card-block-info">*/}
                              {/*          <h6><a href="job-details.html">UI / UX Designer fulltime</a></h6>*/}
                              {/*          <div className="mt-5"><span className="card-briefcase">Fulltime</span><span className="card-time">4<span> minutes ago</span></span></div>*/}
                              {/*          <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur</p>*/}
                              {/*          <div className="mt-30"><a className="btn btn-grey-small mr-5" href="jobs-grid.html">Adobe XD</a><a className="btn btn-grey-small mr-5" href="jobs-grid.html">Figma</a><a className="btn btn-grey-small mr-5" href="jobs-grid.html">Photoshop</a>*/}
                              {/*          </div>*/}
                              {/*          <div className="card-2-bottom mt-30">*/}
                              {/*            <div className="row">*/}
                              {/*              <div className="col-lg-7 col-7"><span className="card-text-price">$500</span><span className="text-muted">/Hour</span></div>*/}
                              {/*              <div className="col-lg-5 col-5 text-end">*/}
                              {/*                <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>*/}
                              {/*              </div>*/}
                              {/*            </div>*/}
                              {/*          </div>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    </div>*/}
                              {/*    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">*/}
                              {/*      <div className="card-grid-2 hover-up">*/}
                              {/*        <div className="card-grid-2-image-left"><span className="flash"></span>*/}
                              {/*          <div className="image-box"><img src="assets/imgs/brands/brand-2.png" alt="jobBox" /></div>*/}
                              {/*          <div className="right-info"><a className="name-job" href="company-details.html">Adobe Ilustrator</a><span className="location-small">New York, US</span></div>*/}
                              {/*        </div>*/}
                              {/*        <div className="card-block-info">*/}
                              {/*          <h6><a href="job-details.html">Full Stack Engineer</a></h6>*/}
                              {/*          <div className="mt-5"><span className="card-briefcase">Part time</span><span className="card-time">5<span> minutes ago</span></span></div>*/}
                              {/*          <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>*/}
                              {/*          <div className="mt-30"><a className="btn btn-grey-small mr-5" href="jobs-grid.html">React</a><a className="btn btn-grey-small mr-5" href="jobs-grid.html">NodeJS</a>*/}
                              {/*          </div>*/}
                              {/*          <div className="card-2-bottom mt-30">*/}
                              {/*            <div className="row">*/}
                              {/*              <div className="col-lg-7 col-7"><span className="card-text-price">$800</span><span className="text-muted">/Hour</span></div>*/}
                              {/*              <div className="col-lg-5 col-5 text-end">*/}
                              {/*                <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>*/}
                              {/*              </div>*/}
                              {/*            </div>*/}
                              {/*          </div>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    </div>*/}
                              {/*    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">*/}
                              {/*      <div className="card-grid-2 hover-up">*/}
                              {/*        <div className="card-grid-2-image-left"><span className="flash"></span>*/}
                              {/*          <div className="image-box"><img src="assets/imgs/brands/brand-3.png" alt="jobBox" /></div>*/}
                              {/*          <div className="right-info"><a className="name-job" href="company-details.html">Bing Search</a><span className="location-small">New York, US</span></div>*/}
                              {/*        </div>*/}
                              {/*        <div className="card-block-info">*/}
                              {/*          <h6><a href="job-details.html">Java Software Engineer</a></h6>*/}
                              {/*          <div className="mt-5"><span className="card-briefcase">Full time</span><span className="card-time">6<span> minutes ago</span></span></div>*/}
                              {/*          <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>*/}
                              {/*          <div className="mt-30"><a className="btn btn-grey-small mr-5" href="jobs-grid.html">Python</a><a className="btn btn-grey-small mr-5" href="jobs-grid.html">AWS</a><a className="btn btn-grey-small mr-5" href="jobs-grid.html">Photoshop</a>*/}
                              {/*          </div>*/}
                              {/*          <div className="card-2-bottom mt-30">*/}
                              {/*            <div className="row">*/}
                              {/*              <div className="col-lg-7 col-7"><span className="card-text-price">$250</span><span className="text-muted">/Hour</span></div>*/}
                              {/*              <div className="col-lg-5 col-5 text-end">*/}
                              {/*                <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>*/}
                              {/*              </div>*/}
                              {/*            </div>*/}
                              {/*          </div>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    </div>*/}
                              {/*    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">*/}
                              {/*      <div className="card-grid-2 hover-up">*/}
                              {/*        <div className="card-grid-2-image-left"><span className="flash"></span>*/}
                              {/*          <div className="image-box"><img src="assets/imgs/brands/brand-4.png" alt="jobBox" /></div>*/}
                              {/*          <div className="right-info"><a className="name-job" href="company-details.html">Dailymotion</a><span className="location-small">New York, US</span></div>*/}
                              {/*        </div>*/}
                              {/*        <div className="card-block-info">*/}
                              {/*          <h6><a href="job-details.html">Frontend Developer</a></h6>*/}
                              {/*          <div className="mt-5"><span className="card-briefcase">Full time</span><span className="card-time">6<span> minutes ago</span></span></div>*/}
                              {/*          <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>*/}
                              {/*          <div className="mt-30"><a className="btn btn-grey-small mr-5" href="jobs-grid.html">Typescript</a><a className="btn btn-grey-small mr-5" href="jobs-grid.html">Java</a>*/}
                              {/*          </div>*/}
                              {/*          <div className="card-2-bottom mt-30">*/}
                              {/*            <div className="row">*/}
                              {/*              <div className="col-lg-7 col-7"><span className="card-text-price">$250</span><span className="text-muted">/Hour</span></div>*/}
                              {/*              <div className="col-lg-5 col-5 text-end">*/}
                              {/*                <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>*/}
                              {/*              </div>*/}
                              {/*            </div>*/}
                              {/*          </div>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    </div>*/}
                              {/*    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">*/}
                              {/*      <div className="card-grid-2 hover-up">*/}
                              {/*        <div className="card-grid-2-image-left"><span className="flash"></span>*/}
                              {/*          <div className="image-box"><img src="assets/imgs/brands/brand-5.png" alt="jobBox" /></div>*/}
                              {/*          <div className="right-info"><a className="name-job" href="company-details.html">Linkedin</a><span className="location-small">New York, US</span></div>*/}
                              {/*        </div>*/}
                              {/*        <div className="card-block-info">*/}
                              {/*          <h6><a href="job-details.html">React Native Web Developer</a></h6>*/}
                              {/*          <div className="mt-5"><span className="card-briefcase">Fulltime</span><span className="card-time">4<span> minutes ago</span></span></div>*/}
                              {/*          <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur</p>*/}
                              {/*          <div className="mt-30"><a className="btn btn-grey-small mr-5" href="jobs-grid.html">Angular</a>*/}
                              {/*          </div>*/}
                              {/*          <div className="card-2-bottom mt-30">*/}
                              {/*            <div className="row">*/}
                              {/*              <div className="col-lg-7 col-7"><span className="card-text-price">$500</span><span className="text-muted">/Hour</span></div>*/}
                              {/*              <div className="col-lg-5 col-5 text-end">*/}
                              {/*                <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>*/}
                              {/*              </div>*/}
                              {/*            </div>*/}
                              {/*          </div>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    </div>*/}
                              {/*    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">*/}
                              {/*      <div className="card-grid-2 hover-up">*/}
                              {/*        <div className="card-grid-2-image-left"><span className="flash"></span>*/}
                              {/*          <div className="image-box"><img src="assets/imgs/brands/brand-6.png" alt="jobBox" /></div>*/}
                              {/*          <div className="right-info"><a className="name-job" href="company-details.html">Quora JSC</a><span className="location-small">New York, US</span></div>*/}
                              {/*        </div>*/}
                              {/*        <div className="card-block-info">*/}
                              {/*          <h6><a href="job-details.html">Senior System Engineer</a></h6>*/}
                              {/*          <div className="mt-5"><span className="card-briefcase">Part time</span><span className="card-time">5<span> minutes ago</span></span></div>*/}
                              {/*          <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>*/}
                              {/*          <div className="mt-30"><a className="btn btn-grey-small mr-5" href="job-details.html">PHP</a><a className="btn btn-grey-small mr-5" href="job-details.html">Android</a>*/}
                              {/*          </div>*/}
                              {/*          <div className="card-2-bottom mt-30">*/}
                              {/*            <div className="row">*/}
                              {/*              <div className="col-lg-7 col-7"><span className="card-text-price">$800</span><span className="text-muted">/Hour</span></div>*/}
                              {/*              <div className="col-lg-5 col-5 text-end">*/}
                              {/*                <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>*/}
                              {/*              </div>*/}
                              {/*            </div>*/}
                              {/*          </div>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    </div>*/}
                              {/*    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">*/}
                              {/*      <div className="card-grid-2 hover-up">*/}
                              {/*        <div className="card-grid-2-image-left"><span className="flash"></span>*/}
                              {/*          <div className="image-box"><img src="assets/imgs/brands/brand-7.png" alt="jobBox" /></div>*/}
                              {/*          <div className="right-info"><a className="name-job" href="company-details.html">Nintendo</a><span className="location-small">New York, US</span></div>*/}
                              {/*        </div>*/}
                              {/*        <div className="card-block-info">*/}
                              {/*          <h6><a href="job-details.html">Products Manager</a></h6>*/}
                              {/*          <div className="mt-5"><span className="card-briefcase">Full time</span><span className="card-time">6<span> minutes ago</span></span></div>*/}
                              {/*          <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>*/}
                              {/*          <div className="mt-30"><a className="btn btn-grey-small mr-5" href="job-details.html">ASP .Net</a><a className="btn btn-grey-small mr-5" href="job-details.html">Figma</a>*/}
                              {/*          </div>*/}
                              {/*          <div className="card-2-bottom mt-30">*/}
                              {/*            <div className="row">*/}
                              {/*              <div className="col-lg-7 col-7"><span className="card-text-price">$250</span><span className="text-muted">/Hour</span></div>*/}
                              {/*              <div className="col-lg-5 col-5 text-end">*/}
                              {/*                <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>*/}
                              {/*              </div>*/}
                              {/*            </div>*/}
                              {/*          </div>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    </div>*/}
                              {/*    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">*/}
                              {/*      <div className="card-grid-2 hover-up">*/}
                              {/*        <div className="card-grid-2-image-left"><span className="flash"></span>*/}
                              {/*          <div className="image-box"><img src="assets/imgs/brands/brand-8.png" alt="jobBox" /></div>*/}
                              {/*          <div className="right-info"><a className="name-job" href="company-details.html">Periscope</a><span className="location-small">New York, US</span></div>*/}
                              {/*        </div>*/}
                              {/*        <div className="card-block-info">*/}
                              {/*          <h6><a href="job-details.html">Lead Quality Control QA</a></h6>*/}
                              {/*          <div className="mt-5"><span className="card-briefcase">Full time</span><span className="card-time">6<span> minutes ago</span></span></div>*/}
                              {/*          <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>*/}
                              {/*          <div className="mt-30"><a className="btn btn-grey-small mr-5" href="job-details.html">iOS</a><a className="btn btn-grey-small mr-5" href="job-details.html">Laravel</a><a className="btn btn-grey-small mr-5" href="job-details.html">Golang</a>*/}
                              {/*          </div>*/}
                              {/*          <div className="card-2-bottom mt-30">*/}
                              {/*            <div className="row">*/}
                              {/*              <div className="col-lg-7 col-7"><span className="card-text-price">$250</span><span className="text-muted">/Hour</span></div>*/}
                              {/*              <div className="col-lg-5 col-5 text-end">*/}
                              {/*                <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>*/}
                              {/*              </div>*/}
                              {/*            </div>*/}
                              {/*          </div>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    </div>*/}
                              {/*    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">*/}
                              {/*      <div className="card-grid-2 hover-up">*/}
                              {/*        <div className="card-grid-2-image-left"><span className="flash"></span>*/}
                              {/*          <div className="image-box"><img src="assets/imgs/brands/brand-8.png" alt="jobBox" /></div>*/}
                              {/*          <div className="right-info"><a className="name-job" href="company-details.html">Periscope</a><span className="location-small">New York, US</span></div>*/}
                              {/*        </div>*/}
                              {/*        <div className="card-block-info">*/}
                              {/*          <h6><a href="job-details.html">Lead Quality Control QA</a></h6>*/}
                              {/*          <div className="mt-5"><span className="card-briefcase">Full time</span><span className="card-time">6<span> minutes ago</span></span></div>*/}
                              {/*          <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>*/}
                              {/*          <div className="mt-30"><a className="btn btn-grey-small mr-5" href="job-details.html">iOS</a><a className="btn btn-grey-small mr-5" href="job-details.html">Laravel</a><a className="btn btn-grey-small mr-5" href="job-details.html">Golang</a>*/}
                              {/*          </div>*/}
                              {/*          <div className="card-2-bottom mt-30">*/}
                              {/*            <div className="row">*/}
                              {/*              <div className="col-lg-7 col-7"><span className="card-text-price">$250</span><span className="text-muted">/Hour</span></div>*/}
                              {/*              <div className="col-lg-5 col-5 text-end">*/}
                              {/*                <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>*/}
                              {/*              </div>*/}
                              {/*            </div>*/}
                              {/*          </div>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    </div>*/}
                              {/*  </div>*/}
                              {/*  <div className="paginations">*/}
                              {/*    <ul className="pager">*/}
                              {/*      <li><a className="pager-prev" href="#"></a></li>*/}
                              {/*      <li><a className="pager-number" href="#">1</a></li>*/}
                              {/*      <li><a className="pager-number" href="#">2</a></li>*/}
                              {/*      <li><a className="pager-number" href="#">3</a></li>*/}
                              {/*      <li><a className="pager-number" href="#">4</a></li>*/}
                              {/*      <li><a className="pager-number" href="#">5</a></li>*/}
                              {/*      <li><a className="pager-number active" href="#">6</a></li>*/}
                              {/*      <li><a className="pager-number" href="#">7</a></li>*/}
                              {/*      <li><a className="pager-next" href="#"></a></li>*/}
                              {/*    </ul>*/}
                              {/*  </div>*/}
                              {/*</div>*/}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
        </Fragment>
    )
}

export default Dashboard;