import React, {Fragment, useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import 'react-international-phone/style.css';
import Header from "./_partials/Header";
import BaseUrl from "./config/baseUrl";
import { PhoneInput } from 'react-international-phone';
import {useImmerReducer} from "use-immer";
import axios from "axios";

// const url = BaseUrl()

//Context
import DispatchContext from '../Context/DispatchContext';
import StateContext from '../Context/StateContext';

const url = BaseUrl();

function Connexion() {
    const navigate = useNavigate()
    const GlobalDispatch = useContext(DispatchContext)
    const GlobalState = useContext(StateContext)
    const [phone, setPhone] = useState('');

    //OnSubmit Function du Formulaire
    function FormSubmit (e) {
        e.preventDefault();
        console.log("formulaire transmis !!!")
        dispatch({type: 'changeSendRequest'})
    }

    const initialState = {
        // userNameValue: "",
        contactValue: "",
        password: "",
        sendRequest: 0,
        token: "",
    }

    function ReducerFunction (draft, action) {
        switch(action.type) {
          // case "catchUsernameChange":
          //     draft.userNameValue = action.usernamechosen;
          // break;

          case "catchContactChange":
            draft.phone = action.contactChosen;
          break;

          case "catchPasswordChange":
              draft.password = action.passwordchosen;
          break;

          case "changeSendRequest":
              draft.sendRequest = draft.sendRequest + 1;
          break;

          case "catchToken":
              draft.token = action.tokenValue;
          break;
        }
    }

    const [state, dispatch] =useImmerReducer(ReducerFunction, initialState)

    //send request for backends to login and get Token
    useEffect(() => {
        if (state.sendRequest) {
            const source = axios.CancelToken.source();
                async function Conexion() {
                    try{
                        const response = await axios
                            .post(url+'/api-auth-djoser/token/login/',
                            {
                                // contact1: phone,
                                contact1: phone,
                                password: state.password,
                            },
                            {
                                cancelToken: source.token
                            }
                            );
                            console.log(response);
                            //recupération du Token générer lors de la premiere Connexion et on le Stock
                            dispatch({
                                type: 'catchToken',
                                tokenValue: response.data.auth_token,
                            });
                            GlobalDispatch({
                                type: 'catchToken',
                                tokenValue: response.data.auth_token,
                            });
                            // navigate('/')
                    } catch (error) {
                        console.log(error.response);
                    }
                }
                Conexion();
                return () => {
                    source.cancel();
                };
            }
    }, [state.sendRequest]);


    //get Login user Informations
    useEffect(() => {
        // vérifie de l'existence du Token avec le IF
        if (state.token !== "") {
            const source = axios.CancelToken.source();
                async function GetUserInfo() {
                    try{
                        const response = await axios
                            .get(url+'/api-auth-djoser/users/me/',
                            {
                                headers: { Authorization: "Token ".concat(state.token)},
                            },
                            {
                                cancelToken: source.token
                            }
                            );
                            console.log(response);
                            GlobalDispatch({
                                type:"userConnecter",
                                usernameInfo: response.data.username,
                                emailInfo: response.data.email,
                                IdInfo: response.data.id,
                            });
                            navigate('/home');
                    } catch (error) {
                        console.log(error.response);
                    }
                }
                GetUserInfo();
                return () => {
                    source.cancel();
                };
            }
    }, [state.token]);

    return (
        <Fragment>
            <Header />
            <main className="main">
              <section className="pt-100 login-register">
                <div className="container">
                  <div className="row login-register-cover">
                    <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                      <div className="text-center">
                        {/*<p className="font-sm text-brand-2">Welcome back! </p>*/}
                        <h2 className="mt-10 mb-5 text-brand-1">Formulaire de Connexion</h2>
                        <div className="divider-text-center"><span></span></div>
                      </div>
                      <form onSubmit={FormSubmit} className="login-register text-start mt-20" action="#">
                        {/*<div className="form-group">*/}
                        {/*  <label className="form-label" htmlFor="username">Nom Utilisateur <span className="text-danger">*</span></label>*/}
                        {/*  /!*<label className="form-label" htmlFor="username">Nom Utilisateur <span className="text-danger">*</span></label>*!/*/}
                        {/*  <input*/}
                        {/*      className="form-control"*/}
                        {/*      style={{borderRadius: "15px"}}*/}
                        {/*      id="username"*/}
                        {/*      type="text"*/}
                        {/*      required=""*/}
                        {/*      placeholder="Entré votre adresse Email"*/}
                        {/*      value={state.userNameValue}*/}
                        {/*      onChange={(e)=>dispatch({*/}
                        {/*          type:'catchUsernameChange',*/}
                        {/*          usernamechosen: e.target.value*/}
                        {/*      })}*/}
                        {/*  />*/}
                        {/*</div>*/}
                          <div className="form-group">
                              <label className="form-label" htmlFor="input-1">Contact <span className="text-danger">*</span></label>
                              <PhoneInput
                                  id="contact1"
                                  style={{width: "100%", borderRadius: "15px"}}
                                  defaultCountry="ci"
                                  value={phone}
                                  onChange={(phone) => setPhone(phone)}
                                  required
                              />
                          </div>
                          <div className="form-group">
                              <label className="form-label" htmlFor="password">Mot de Passe <span
                                  className="text-danger">*</span></label>
                              <input
                                  className="form-control"
                                  style={{borderRadius: "15px"}}
                                  id="password"
                                  type="password"
                                  required=""
                                  placeholder="************"
                                  value={state.password}
                                  onChange={(e)=>dispatch({
                                       type:'catchPasswordChange',
                                       passwordchosen: e.target.value
                                  })}
                              />
                        </div>
                        <div className="login_footer form-group d-flex justify-content-between">
                          <label className="cb-container">
                            <input type="checkbox" /><span className="text-small">Remenber me</span><span className="checkmark"></span>
                          </label><a className="text-muted" href="page-contact.html">Forgot Password</a>
                        </div>
                        <div className="form-group">
                          <button className="btn btn-brand-1 hover-up w-100" type="submit" name="login" style={{borderRadius:"32px"}}>Connexion</button>
                        </div>
                        {/*<div className="text-muted text-center">Don't have an Account? <a href="page-signin.html">Sign up</a></div>*/}
                        <div className="col-12 text-center">
                            <p className="mb-0 mt-3 text-center">
                                <small className="text-dark me-2">Pas encore de Inscrit ?</small>
                                <Link to="/inscription" className="text-Info fw-semibold" style={{ fontSize: "22px" }}> Inscription</Link>
                            </p>
                        </div>
                        <div className="text-center">
                          <p className="mb-0">© {new Date().getFullYear()} Yaako. Copyright <i className="mdi mdi-heart text-danger"></i>
                              <a href="#" target="_blank" className="text-reset">Tous Droits Réservés</a>.
                          </p>
                      </div>
                      </form>
                    </div>
                    {/* <div className="img-1 d-none d-lg-block">
                        <img className="shape-1" src="assets/imgs/page/login-register/img-4.svg" alt="JobBox" />
                    </div>
                    <div className="img-2">
                        <img src="assets/imgs/page/login-register/img-3.svg" alt="JobBox" />
                    </div> */}
                  </div>
                </div>
              </section>
            </main>
        </Fragment>
    )
}

export default Connexion;