import React, {useContext, useEffect, useState, Fragment} from "react";
import Swal from "sweetalert2";
import {Link, useNavigate} from "react-router-dom";
import 'react-international-phone/style.css';
import {useImmerReducer} from "use-immer";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import StateContext from "../Context/StateContext";
import moment from "moment";
import Header from "./_partials/Header";
import BaseUrl from "./config/baseUrl";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
registerLocale("fr", fr); // register it with the name you want

// const baseUrl = "http://127.0.0.1:8000/api";

const url = BaseUrl();

function AddAnnonce() {
    const GlobalState = useContext(StateContext)
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());

    const initialState = {
        titreValue: "",
        categorieValue: "",
        dateValue: "",
        programmeValue: "",
        fairepartValue: "",
        image1Value: "",
        image2Value: "",
        image3Value: "",
        image4Value: "",
        image5Value: "",
        sendRequest: 0,
        uploadedImages: [],
        // userProfile: {
        //
        // }
    }

    const CatOption = [
        {
            value: '',
            label: '',
        },
        {
            value: 'DECES',
            label: 'DECES',
        },
        {
            value: 'IN MEMORIAM',
            label: 'IN MEMORIAM',
        },
    ]

    const TypeAnnonce  = [
        {
            value: '',
            label: '',
        },
        {
            value: 'PREMIUM',
            label: 'PREMIUM',
        },
        {
            value: 'PRIVILEGE',
            label: 'PRIVILEGE',
        },
        {
            value: 'GRATUITE',
            label: 'GRATUITE',
        },
    ]

    function ReducerFunction (draft, action) {
        switch(action.type) {
          case "catchTypeChange":
              draft.typeValue = action.typeChosen;
          break;

          case "catchCategorieChange":
                draft.categorieValue = action.categorieChosen;
          break;

          case "catchTitreChange":
            draft.titreValue = action.titreChosen;
          break;

          case "catchDateChange":
            draft.dateValue = action.dateChosen;
          break;

          case "catchProgrammeChange":
            draft.programmeValue = action.programmeChosen;
          break;

          case "catchFairepartChange":
            draft.fairepartValue = action.fairepartChosen;
          break;

          case "catchImage1Change":
            draft.image1Value = action.image1Chosen;
        break;

        case "catchImage2Change":
            draft.image2Value = action.image2Chosen;
        break;

        case "catchImage3Change":
            draft.image3Value = action.image3Chosen;
        break;

        case "catchImage4Change":
            draft.image4Value = action.image4Chosen;
        break;

        case "catchImage5Change":
            draft.image5Value = action.image5Chosen;
        break;

        case "catchUploadedImages":
            draft.uploadedImages = action.ImagesChosen;
        break;

          case "changeSendRequest":
          draft.sendRequest = draft.sendRequest + 1;
          break;
        }
    }
    const [state, dispatch] =useImmerReducer(ReducerFunction, initialState)

    //request to get Profile info
    useEffect(() => {
        async function GetProfileInfo() {
            try {
                const response = await axios
                    .get(url+`/profiles/${GlobalState.userId}/`);
                    console.log(response.data);
                    dispatch({type: 'catchProfileInfo', profileObject: response.data})
            }catch (e) {
                console.log(e.response);
            }
        }
        GetProfileInfo();
    }, []);

    //OnSubmit Function du Formulaire
    function FormSubmit (e) {
        e.preventDefault();
        console.log("formulaire transmis !!!")
        dispatch({type: 'changeSendRequest'})
    }

    //useEffect to catch Image Submit Files catchUploadedImages
    useEffect(() => {
        if(state.uploadedImages[0]) {
            dispatch(
                {
                    type: 'catchImage1Change',
                    image1Chosen: state.uploadedImages[0],
                }
            );
        }
      }, [state.uploadedImages[0]]);

  useEffect(() => {
    if(state.uploadedImages[1]) {
        dispatch(
            {
                type: 'catchImage2Change',
                image2Chosen: state.uploadedImages[1],
            }
        );
    }
  }, [state.uploadedImages[1]]);

  useEffect(() => {
    if(state.uploadedImages[2]) {
        dispatch(
            {
                type: 'catchImage3Change',
                image3Chosen: state.uploadedImages[2],
            }
        );
    }
  }, [state.uploadedImages[2]]);

  useEffect(() => {
    if(state.uploadedImages[3]) {
        dispatch(
            {
                type: 'catchImage4Change',
                image4Chosen: state.uploadedImages[3],
            }
        );
    }
  }, [state.uploadedImages[3]]);

  useEffect(() => {
    if(state.uploadedImages[4]) {
        dispatch(
            {
                type: 'catchImage5Change',
                image5Chosen: state.uploadedImages[4],
            }
        );
    }
  }, [state.uploadedImages[4]]);

    // fontion de raitement du Formulaire avant Envoie au Serveur
    useEffect(()=>{
    if(state.sendRequest) {
            async function AddAnnonce() {
                const formData = new FormData()
                formData.append('user', GlobalState.userId);
                formData.append('titre', state.titreValue);
                formData.append('programme', state.programmeValue);
                formData.append('faire_part', state.fairepartValue);
                formData.append('type_annonce', state.typeValue);
                formData.append('categorie', state.categorieValue);
                formData.append('image1', state.image1Value);
                formData.append('image2', state.image2Value);
                formData.append('image3', state.image3Value);
                formData.append('image4', state.image4Value);
                formData.append('image5', state.image5Value);
                formData.append('date', moment(startDate).utc().format('YYYY-MM-DD'));
                console.log(formData);

                Swal.fire({
                    title: 'Enregistrement...',
                    html: 'Veillez patientez...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                      Swal.showLoading()
                    },
                });
               try {
                // Swal.close()
                const response = await axios
                    .post(url+"/annonces/add/", formData);
                    Swal.fire({
                      title: 'FELICITATION !',
                      html: "Annonce : <b>"+state.titreValue+"</b> enregistrée avec Succès",
                      icon: 'success',
                      showCancelButton: false,
                      confirmButtonColor: '#3085d6',
                      confirmButtonText: 'OK'
                    })
                    // .post("http://127.0.0.1:8000/api/annonces/add/", formData);
                    // .post("http://127.0.0.1:8000/api/annonces/create/", formData);
                    console.log(response.data);
                     // if (response.status === 201) {
                     navigate("/home");
               } catch(e) {
                console.log(e.response)
               }
            }
            AddAnnonce()
        }
    }, [state.sendRequest])

    return (
        <Fragment>
            <Header />
            <main className="main">
              <section className="login-register">
                <div className="container">
                  <div className="row login-register-cover">
                    <div className="col-lg-8 col-md-6 col-sm-12 mx-auto">
                      <div className="text-center">
                        <h2 className="mt-10 mb-5 text-brand-1">Formulaire Création Annonce</h2>
                        <div className="divider-text-center"><span></span></div>
                      </div>
                      <form onSubmit={FormSubmit} className="login-register text-start" action="#">
                        <div className="form-group">
                          <label className="form-label" htmlFor="categorie">Type Annonce <span className="text-danger">*</span></label>
                            <Form.Select
                                style={{padding: "10px", borderRadius: "15px"}}
                                aria-label=""
                                value={state.typeValue}
                                onChange={(e)=>dispatch({
                                    type:'catchTypeChange',
                                    typeChosen: e.target.value
                                })}
                            >
                                {TypeAnnonce.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="categorie">Catégorie <span className="text-danger">*</span></label>
                            <Form.Select
                                style={{padding: "10px", borderRadius: "15px"}}
                                aria-label=""
                                value={state.categorieValue}
                                onChange={(e)=>dispatch({
                                    type:'catchCategorieChange',
                                    categorieChosen: e.target.value
                                })}
                            >
                                {CatOption.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="titre">Titre <span className="text-danger">*</span></label>
                          <input
                              id="titre"
                              style={{padding: "5px", borderRadius: "15px"}}
                              type="text"
                              className="form-control"
                              placeholder="Titre"
                              required=""
                              value={state.titreValue}
                              onChange={(e)=>dispatch({
                                type:'catchTitreChange',
                                titreChosen: e.target.value})
                              }
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="date">Date <span className="text-danger">*</span></label>
                          <DatePicker className="form-control"
                                id="date"
                                style={{width: "100%", padding:"15px", borderRadius: "15px",}}
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                locale="fr"
                                dateFormat="yyyy-MM-dd"
                                maxDate={startDate}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="programme">Faire-part <span className="text-danger">*</span></label>
                          <textarea
                                style={{padding: "30px", borderRadius: "15px", height: "130px"}}
                                className="form-control"
                                placeholder="Faire-part"
                                id="faire_part"
                                rows="8"
                                value={state.fairepartValue}
                                onChange={(e)=>dispatch({
                                    type:'catchFairepartChange',
                                    fairepartChosen: e.target.value}
                                )}
                          />
                        </div>

                        <div className="form-group">
                          <label className="form-label" htmlFor="programme">Programme <span className="text-danger">*</span></label>
                          <textarea
                                style={{padding: "30px", borderRadius: "15px", height: "130px"}}
                                className="form-control"
                                placeholder="Programme"
                                id="programme"
                                rows="8"
                                value={state.programmeValue}
                                onChange={(e)=>dispatch({
                                    type:'catchProgrammeChange',
                                    programmeChosen: e.target.value}
                                )}
                          />
                        </div>

                        <div className="form-group">
                            <label htmlFor="formFileMultiple" className="form-label">AJOUTER FAIRE-PART(MAX 5 IMAGES)</label>
                            <input
                                style={{borderRadius: "15px"}}
                                type='file'
                                multiple
                                accept='image/png, image/gif, image/jpeg'
                                onChange={
                                    (e)=>dispatch({
                                        type:"catchUploadedImages",
                                        ImagesChosen:e.target.files
                                    })
                            }/>
                        </div>
                        <div className="form-control mb-10">
                            <ul>
                                {state.image1Value ? <li>{state.image1Value.name}</li> : ""}
                                {state.image2Value ? <li>{state.image2Value.name}</li> : ""}
                                {state.image3Value ? <li>{state.image3Value.name}</li> : ""}
                                {state.image4Value ? <li>{state.image4Value.name}</li> : ""}
                                {state.image5Value ? <li>{state.image5Value.name}</li> : ""}
                            </ul>
                        </div>
                        <div className="form-group">
                          <button className="btn btn-brand-1 hover-up w-100" type="submit" style={{borderRadius: "32px"}}>
                              Soumettre
                          </button>
                        </div>
                      </form>
                      <div className="text-center">
                          <p className="mb-0">© {new Date().getFullYear()} KoliaC. Copyright <i className="mdi mdi-heart text-danger"></i> <a href="#" target="_blank" className="text-reset">Tous Droits Réservés</a>.</p>
                      </div>
                    </div>
                    <div className="img-1 d-none d-lg-block"><img className="shape-1" src="assets/imgs/page/login-register/img-1.svg" alt="JobBox" /></div>
                    <div className="img-2"><img src="assets/imgs/page/login-register/img-2.svg" alt="JobBox" /></div>
                  </div>
                </div>
              </section>
            </main>
        </Fragment>
    )

}

export default AddAnnonce;
