import React, {Fragment, useContext, useEffect, useState} from 'react';
import Loader from "./assets/imgs/template/loading.gif"
import {Link, useNavigate} from "react-router-dom";
import Typewriter from "typewriter-effect";
import Header from "./_partials/Header";
import Footer from "./_partials/Footer";
import DetailAnnonce from "./DetailAnnonce";
import axios from "axios";
import BaseUrl from "./config/baseUrl";
import StateContext from "../Context/StateContext";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import Navbar from "./_partials/Navbar";
import annonces from "./Annonces";

import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {EffectCoverflow, Pagination, Navigation} from "swiper/modules";

import BgImage1 from './assets/imgs/page/homepage2/img-big1.png'
import BgImage2 from './assets/imgs/page/homepage2/img-big2.png'
import BgImage3 from './assets/imgs/page/homepage2/img-big3.png'
import BgImage4 from './assets/imgs/page/homepage2/img-big1.png'
import BgImage5 from './assets/imgs/page/homepage2/img-big2.png'
import BgImage6 from './assets/imgs/page/homepage2/img-big3.png'
import Location1 from './assets/imgs/page/homepage1/location1.png'
import Location2 from './assets/imgs/page/homepage1/location2.png'
import Location3 from './assets/imgs/page/homepage1/location3.png'

const url = BaseUrl();

function Home() {
    const [annonces, setAnnonces] = useState([]);
    const [lastannonces, setLastAnnonces] = useState([]);
    const [lastannoncesPremium, setLastAnnoncesPremium] = useState([]);
    const [lastannoncesPrivilege, setLastAnnoncesPrivilege] = useState([]);
    const [lastannoncesdeces, setLastAnnoncesDeces] = useState([]);
    const [lastannoncesmemoriam, setLastAnnoncesInMemoriam] = useState([]);
    const [dataIsloading, setDataIsLoading] = useState(true)
    const navigate = useNavigate();
    const GlobalState = useContext(StateContext);


    function ButtonDisplay() {
        if(GlobalState.userIsConnecter) {
        return (
            <div className="col-lg-8 offset-2 justify-content-center text-center"
                style={{
                    padding: "40px",
                    background: "rgb(55, 202, 227)",
                    border: "10px solid white",
                    textAlign: "center",
                    color: "rgb(255, 255, 255)",
                    fontWeight: "bold",
                    borderRadius: "32px",
                    boxShadow: "grey 1px 1px 1px",
                    marginTop: "25px",
                    marginBottom: "25px",
                }}
            >
                <Link to="/pricing" style={{color: "#FFFFFF", borderRadius: "30px", fontWeight: "bold", fontSize: "36px"}}>
                    Créer une Annonce
                </Link>
            </div>
        )
        } else if (!GlobalState.userIsConnecter) {
        return (
            <div className="col-lg-8 offset-2 justify-content-center text-center"
                style={{
                    padding: "40px",
                    background: "#ff9f40",
                    // background: "rgb(203,107,31)",
                    border: "10px solid white",
                    textAlign: "center",
                    color: "rgb(255, 255, 255)",
                    fontWeight: "bold",
                    borderRadius: "32px",
                    boxShadow: "grey 1px 1px 1px",
                    marginTop: "25px",
                    marginBottom: "25px",
                }}
            >
                <Link to="/connexion" style={{color: "#FFFFFF", borderRadius: "30px", fontWeight: "bold", fontSize: "36px"}}>
                    Créer une Annonce
                </Link>
            </div>
        )
        }
    }

    //from data from backend with axios using UseEffect
    useEffect(() => {
        const source = axios.CancelToken.source();
        async function GetAnnonces() {
            try{
                const response = await axios
                    .get(
                        url+'/annonces/',
                         { cancelToken: source.token }
                    );
                setAnnonces(response.data);
                setDataIsLoading(false)
            } catch (error) {
                console.log(error.response);
            }
        }
        GetAnnonces();
        return () => {
            source.cancel();
        };
    }, []);

    //Get all Last Premium
    useEffect(() => {
        const source = axios.CancelToken.source();
        async function GetLastAnnoncesPremium() {
            try{
                const response = await axios
                    .get(
                        url+'/LastAnnoncepremium/',
                         { cancelToken: source.token }
                    );
                setLastAnnoncesPremium(response.data);
                setDataIsLoading(false)
            } catch (error) {
                console.log(error.response);
            }
        }
        GetLastAnnoncesPremium();
        return () => {
            source.cancel();
        };
    }, []);

    //Get all Last Privilege
    useEffect(() => {
        const source = axios.CancelToken.source();
        async function GetLastAnnoncesPrivilege() {
            try{
                const response = await axios
                    .get(
                        url+'/LastAnnonceprivilege/',
                         { cancelToken: source.token }
                    );
                setLastAnnoncesPrivilege(response.data);
                setDataIsLoading(false)
            } catch (error) {
                console.log(error.response);
            }
        }
        GetLastAnnoncesPrivilege();
        return () => {
            source.cancel();
        };
    }, []);

    //Get all Last Annonces
    useEffect(() => {
        const source = axios.CancelToken.source();
        async function GetLastAnnonces() {
            try{
                const response = await axios
                    .get(
                        url+'/lastannonces/',
                         { cancelToken: source.token }
                    );
                setLastAnnonces(response.data);
                setDataIsLoading(false)
            } catch (error) {
                console.log(error.response);
            }
        }
        GetLastAnnonces();
        return () => {
            source.cancel();
        };
    }, []);

     //Get Last Annonces Deces
    useEffect(() => {
        const source = axios.CancelToken.source();
        async function GetLastAnnoncesDeces() {
            try{
                const response = await axios
                    .get(
                        url+'/LastAnnonceDeces/',
                         { cancelToken: source.token }
                    );
                setLastAnnoncesDeces(response.data);
                setDataIsLoading(false)
            } catch (error) {
                console.log(error.response);
            }
        }
        GetLastAnnoncesDeces();
        return () => {
            source.cancel();
        };
    }, []);

   //Get Last Annonces In Memoriam
    useEffect(() => {
        const source = axios.CancelToken.source();
        async function GetLastAnnoncesInMemoriam() {
            try{
                const response = await axios
                    .get(
                        url+'/LastAnnonceInmemoriam/',
                         { cancelToken: source.token }
                    );
                setLastAnnoncesInMemoriam(response.data);
                setDataIsLoading(false)
            } catch (error) {
                console.log(error.response);
            }
        }
        GetLastAnnoncesInMemoriam();
        return () => {
            source.cancel();
        };
    }, []);

    const date = new Date(lastannonces.add_le)
    const FormattedDate = `${date.getDate() + 1}/${date.getMonth()}/${date.getFullYear()}`

    // console.log(FormattedDate)

    if(dataIsloading === false) {
        console.log(lastannonces);
    }

    if (dataIsloading === true) {
      return (

      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="text-center"><img src={Loader} alt="jobBox" /></div>
          </div>
        </div>
      </div>

      );
    }

    return (
        <Fragment>
            <Header />
            {/*<Navbar />*/}
            <main className="main">
              <section className="section-box">
                <div className="banner-hero hero-2">
                  <div className="banner-inner">
                    <div className="block-banner">
                      <h1 className="text-42 mt-100 color-white wow animate__animated animate__fadeInUp"><span style={{fontWeight: "52px"}}>Yaako, </span> <br className="d-none d-lg-block" /><span className="" style={{color: "#D9C57A"}}> Vous ne serez jamais seuls</span></h1>
                      <div className="font-lg font-regular color-white mt-20 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                          {/*<span className="typewrite text-42" data-period="2000" style={{color: "#08186E", fontWeight: "bolder"}}>*/}
                          <span className="typewrite text-42" data-period="2000" style={{color: "#FFFFFF", fontWeight: "bolder"}}>
                              <Typewriter
                                    options={{
                                      strings: ["Décès", "In Mémoriam", "Remerciements"],
                                      autoStart: true,
                                      loop: true,
                                    }}
                              />
                          </span>
                      </div>
                      <div data-wow-delay=".2s" className="text-center justify-content-center animate__animated animate__fadeInUp mb-180">
                            {ButtonDisplay()}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section-box mt-50">
                <div className="container">
                  <div className="text-start">
                    {/*<h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">Annonces Premium</h2>*/}
                    {/*<p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">*/}
                    {/*    Espace Réservé au Annonces Prémium  <a href="">Voir Conditions</a>*/}
                    {/*</p>*/}
                  </div>
                    <Swiper
                        // id="swiper"
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        // slidesPerView={'auto'}
                        slidesPerView={3}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier:2.5
                        }}
                        slidesPerColumn={2}
                        slidesPerColumnFill="row"
                        spaceBetween={15}
                        slidesPerGroup={2}
                        autoplay={true}
                        loop={true}
                        pagination={{el: ".swiper-pagination", clickable:true}}
                        navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                            clickable: true,
                        }}
                        modules = {[EffectCoverflow, Pagination, Navigation]}
                        className="swiper_container box-swiper mt-50"
                    >
                      <div className="swiper-container swiper-group-6 mh-none swiper">
                        <div className="swiper-wrapper pb-70 pt-5">
                            {lastannoncesPremium.map((premium,  index) => (
                                <SwiperSlide className="swiper-slide hover-up">
                                    {/*<div className="card-grid-5 card-category hover-up"*/}
                                    <div className="card-grid-5 hover-up"
                                         // onClick={() =>navigate(
                                         //        `/annonces/${premium.id}`
                                         // )}
                                         style={{
                                             backgroundImage: `url(${premium.image1})`,
                                             backgroundPosition: 'center',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat'
                                         }}
                                    >
                                        <a onClick={() =>navigate(
                                                `/annonces/${premium.id}`
                                            )}
                                           href="">
                                            <div className="box-cover-img">
                                              <div className="content-bottom">
                                                <h6 className="color-white mb-5">{premium.titre}</h6>
                                                <p className="color-white font-xs"><span>{premium.date}</span></p>
                                              </div>
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </div>
                      </div>
                        <div className="swiper-button-next swiper-button-next-1"></div>
                        <div className="swiper-button-prev swiper-button-prev-1"></div>
                        <div className="swiper-pagination">
                        </div>
                    </Swiper>
                </div>
                <div className="text-center">
                      <Link className="btn btn-brand-1 btn-icon-load hover-up" to="/annonces">Voir plus d'Annonces</Link>
                  </div>
              </section>
              <section className="section-box mt-50">
                <div className="section-box wow animate__animated animate__fadeIn">
                  <div className="container">
                    {/*<div className="text-start">*/}
                    {/*  <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">Annonces Prestiges</h2>*/}
                    {/*  <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">*/}
                    {/*      Espace Réservé Aux Annonces Prestige, <a href="">Voir Conditions</a>*/}
                    {/*  </p>*/}
                    {/*</div>*/}
                    <Swiper
                        // id="swiper"
                        slidesPerView={5}
                        slidesPerColumn={2}
                        slidesPerColumnFill="row"
                        spaceBetween={15}
                        slidesPerGroup={2}
                        autoplay={true}
                        loop={true}
                        pagination={{el: ".swiper-pagination", clickable:true}}
                        navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        }}
                        modules = {[EffectCoverflow, Pagination, Navigation]}
                        className="box-swiper mt-50"
                    >
                      <div className="swiper-container swiper-group-6 mh-none swiper">
                        <div className="swiper-wrapper pb-70 pt-5">
                            {lastannoncesPrivilege.map((privilege, index) => (
                                <SwiperSlide className="swiper-slide hover-up">
                                    <div className="card-grid-5 card-category hover-up"
                                         // onClick={() =>navigate(
                                         //        `/annonces/${privilege.id}`
                                         //    )}
                                         style={{
                                             backgroundImage: `url(${privilege.image1})`,
                                             backgroundPosition: 'center',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat'
                                         }}
                                    >
                                        <a onClick={() =>navigate(
                                                `/annonces/${privilege.id}`
                                            )}
                                           href="">
                                            <div className="box-cover-img">
                                              <div className="content-bottom">
                                                <h6 className="color-white mb-5">{privilege.titre}</h6>
                                                <p className="color-white font-xs"><span>{privilege.date}</span></p>
                                              </div>
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </div>
                      </div>
                        <div className="swiper-button-next swiper-button-next-1"></div>
                        <div className="swiper-button-prev swiper-button-prev-1"></div>
                        <div className="swiper-pagination">

                        </div>
                    </Swiper>
                  </div>
                </div>
                <div className="text-center">
                      <Link className="btn btn-brand-1 btn-icon-load hover-up" to="/annonces">Voir plus d'Annonces</Link>
                  </div>
              </section>
              <section className="section-box mt-30">
                <div className="container">
                  <div className="text-start">
                    <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">Dernières Annonces</h2>
                    {/*<p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">Search and connect with the right candidates faster.</p>*/}
                    <div className="list-tabs mt-40">
                      <ul className="nav nav-tabs center" role="tablist" style={{textAlign: "center"}}>
                        <li><a className="active" id="nav-tab-job-1" href="#tab-job-1" data-bs-toggle="tab" role="tab" aria-controls="tab-job-1" aria-selected="true" style={{fontWeight:"bolder", fontSize: "16px"}}>
                            <img src="assets/imgs/page/homepage1/management.svg" alt="jobBox" /> Tous</a>
                        </li>
                        <li><a id="nav-tab-job-2" href="#tab-job-2" data-bs-toggle="tab" role="tab" aria-controls="tab-job-2" aria-selected="true" style={{fontWeight:"bolder", fontSize: "16px"}}>
                            <img src="assets/imgs/page/homepage1/human.svg" alt="jobBox" /> DECES</a>
                        </li>
                        <li><a id="nav-tab-job-3" href="#tab-job-3" data-bs-toggle="tab" role="tab" aria-controls="tab-job-3" aria-selected="true" style={{fontWeight:"bolder", fontSize: "16px"}}>
                            <img src="assets/imgs/page/homepage1/content.svg" alt="jobBox" /> In Mémoriam</a>
                        </li>
                        {/*   <li><a id="nav-tab-job-2" href="#tab-job-2" data-bs-toggle="tab" role="tab" aria-controls="tab-job-2" aria-selected="true" style={{fontWeight:"bolder", fontSize: "16px"}}>*/}
                        {/*    <img src="assets/imgs/page/homepage1/human.svg" alt="jobBox" /> Privilège</a>*/}
                        {/*</li>*/}
                        {/*   <li><a id="nav-tab-job-2" href="#tab-job-2" data-bs-toggle="tab" role="tab" aria-controls="tab-job-2" aria-selected="true" style={{fontWeight:"bolder", fontSize: "16px"}}>*/}
                        {/*    <img src="assets/imgs/page/homepage1/human.svg" alt="jobBox" /> Prémium</a>*/}
                        {/*</li>*/}
                      </ul>
                    </div>
                  </div>
                  <div className="mt-50">
                    <div className="tab-content" id="myTabContent-1">
                        <div className="tab-pane fade show active" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">
                        <div className="row">
                          {lastannonces.map((annonce, index) =>(
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12" key={annonce.id}>
                                 {/* <div className="col-lg-2 col-md-6" key={annonce.id}> */}
                                <div className="card-grid-2 grid-bd-16 hover-up">
                                    <div className="card-grid-2-image">
                                        {annonce.categorie === "DECES" ?
                                            <span className="lbl-hot" style={{backgroundColor: "red"}}><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span> :
                                            <span className="lbl-hot"><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span>
                                        }
                                    <div className="image-box">
                                        {annonce.image1 ?
                                            <figure>
                                                  <img
                                                      src={annonce.image1}
                                                      // width={150}
                                                      // height={150}
                                                      style={{cursor: "pointer"}}
                                                      // style={{width: "100%", height: "250px"}}
                                                      alt={annonce.titre}
                                                      onClick={() =>navigate(
                                                          `/annonces/${annonce.id}`
                                                      )}
                                                  />
                                            </figure> :
                                            <figure>
                                                  <img
                                                      src={annonce.qr_code}
                                                      width={350}
                                                      height={300}
                                                      style={{cursor: "pointer"}}
                                                      // style={{width: "100%", height: "250px"}}
                                                      alt={annonce.titre}
                                                      onClick={() =>navigate(
                                                          `/annonces/${annonce.id}`
                                                      )}
                                                  />
                                            </figure>
                                        }
                                    </div>
                                  </div>
                                    <div className="card-block-info">
                                        {/*<div className="tags mb-15"><a className="btn btn-tag" href="blog-grid.html">News</a></div>*/}
                                        <h5>
                                            <Link to={`/annonces/${annonce.id}`} style={{fontSize: "13px"}}>
                                                {annonce.titre.substring(0,40)} ...
                                            </Link>
                                        </h5>
                                        {/*<p className="mt-10 color-text-paragraph font-sm">*/}
                                        {/*  {annonce.programme.substring(0,100)} ...*/}
                                        {/*</p>*/}
                                        <div className="card-2-bottom mt-20">
                                          <div className="row">
                                            <div className="col-lg-6 col-6">
                                              <div className="d-flex">
                                                {annonce.user.image ?
                                                    <img className="img-rounded" src={annonce.user.image} /> :
                                                    <img className="img-rounded" src="assets/imgs/page/homepage1/user1.png" alt="jobBox" />
                                                }
                                                <div className="info-right-img"><span className="font-sm font-bold color-brand-1 op-70">{annonce.user.nom}</span></div>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 text-end col-6 pt-15" style={{marginTop: "-15px"}}>
                                                Posté le : <span className="font-xs color-text-paragraph-2">{annonce.date}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                        <div className="tab-pane fade" id="tab-job-2" role="tabpanel" aria-labelledby="tab-job-2">
                        <div className="row d-flex justify-content-center">
                          {lastannoncesdeces.map((annonce, index) =>(
                                // <div className="col-lg-2 col-md-6" key={annonce.id}>
                                // <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12" key={annonce.id}>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" key={annonce.id}>
                                {/*<div className="col-lg-2 col-md-6" key={annonce.id}>*/}
                                {/*<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" key={annonce.id}>*/}
                                <div className="card-grid-2 grid-bd-16 hover-up">
                                    <div className="card-grid-2-image">
                                        {annonce.categorie === "DECES" ?
                                            <span className="lbl-hot" style={{backgroundColor: "red"}}><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span> :
                                            <span className="lbl-hot"><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span>
                                        }
                                    <div className="image-box">
                                        {annonce.image1 ?
                                            <figure>
                                                  <img
                                                      src={annonce.image1}
                                                      width={350}
                                                      height={300}
                                                      style={{cursor: "pointer"}}
                                                      // style={{width: "100%", height: "250px"}}
                                                      alt={annonce.titre}
                                                      onClick={() =>navigate(
                                                          `/annonces/${annonce.id}`
                                                      )}
                                                  />
                                            </figure> :
                                            <figure>
                                                  <img
                                                      src={annonce.qr_code}
                                                      width={350}
                                                      height={300}
                                                      style={{cursor: "pointer"}}
                                                      // style={{width: "100%", height: "250px"}}
                                                      alt={annonce.titre}
                                                      onClick={() =>navigate(
                                                          `/annonces/${annonce.id}`
                                                      )}
                                                  />
                                            </figure>
                                        }
                                    </div>
                                  </div>
                                    <div className="card-block-info">
                                        {/*<div className="tags mb-15"><a className="btn btn-tag" href="blog-grid.html">News</a></div>*/}
                                        <h5>
                                            <Link to={`/annonces/${annonce.id}`}>
                                                {annonce.titre.toUpperCase()}
                                            </Link>
                                        </h5>
                                        <p className="mt-10 color-text-paragraph font-sm">
                                          {annonce.programme.substring(0,100)} ...
                                        </p>
                                        <div className="card-2-bottom mt-20">
                                          <div className="row">
                                            <div className="col-lg-6 col-6">
                                              <div className="d-flex">
                                                {annonce.user.image ?
                                                    <img className="img-rounded" src={annonce.user.image} /> :
                                                    <img className="img-rounded" src="assets/imgs/page/homepage1/user1.png" alt="jobBox" />
                                                }
                                                <div className="info-right-img"><span className="font-sm font-bold color-brand-1 op-70">{annonce.user.nom}</span></div>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 text-end col-6 pt-15" style={{marginTop: "-15px"}}>
                                                Posté le : <span className="font-xs color-text-paragraph-2">{annonce.date}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                        <div className="tab-pane fade" id="tab-job-3" role="tabpanel" aria-labelledby="tab-job-3">
                        <div className="row">
                          {lastannoncesmemoriam.map((annonce, index) =>(
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" key={annonce.id}>
                                <div className="card-grid-2 grid-bd-16 hover-up">
                                    <div className="card-grid-2-image">
                                        {annonce.categorie === "DECES" ?
                                            <span className="lbl-hot" style={{backgroundColor: "red"}}><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span> :
                                            <span className="lbl-hot"><span style={{fontWeight:"bolder", fontSize: "14px"}}>{annonce.categorie}</span></span>
                                        }
                                    <div className="image-box">
                                        {annonce.image1 ?
                                            <figure>
                                                  <img
                                                      src={annonce.image1}
                                                      width={350}
                                                      height={300}
                                                      style={{cursor: "pointer"}}
                                                      // style={{width: "100%", height: "250px"}}
                                                      alt={annonce.titre}
                                                      onClick={() =>navigate(
                                                          `/annonces/${annonce.id}`
                                                      )}
                                                  />
                                            </figure> :
                                            <figure>
                                                  <img
                                                      src={annonce.qr_code}
                                                      width={350}
                                                      height={300}
                                                      style={{cursor: "pointer"}}
                                                      // style={{width: "100%", height: "250px"}}
                                                      alt={annonce.titre}
                                                      onClick={() =>navigate(
                                                          `/annonces/${annonce.id}`
                                                      )}
                                                  />
                                            </figure>
                                        }
                                    </div>
                                  </div>
                                    <div className="card-block-info">
                                        {/*<div className="tags mb-15"><a className="btn btn-tag" href="blog-grid.html">News</a></div>*/}
                                        <h5>
                                            <Link to={`/annonces/${annonce.id}`}>
                                                {annonce.titre.toUpperCase()}
                                            </Link>
                                        </h5>
                                        <p className="mt-10 color-text-paragraph font-sm">
                                          {annonce.programme.substring(0,100)} ...
                                        </p>
                                        <div className="card-2-bottom mt-20">
                                          <div className="row">
                                            <div className="col-lg-6 col-6">
                                              <div className="d-flex">
                                                {annonce.user.image ?
                                                    <img className="img-rounded" src={annonce.user.image} /> :
                                                    <img className="img-rounded" src="assets/imgs/page/homepage1/user1.png" alt="jobBox" />
                                                }
                                                <div className="info-right-img"><span className="font-sm font-bold color-brand-1 op-70">{annonce.user.nom}</span></div>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 text-end col-6 pt-15" style={{marginTop: "-15px"}}>
                                                Posté le : <span className="font-xs color-text-paragraph-2">{annonce.date}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                </div>
                              </div>
                            ))}
                        </div>
                    </div>
                    </div>
                  </div>

                  <div className="text-center">
                      <Link className="btn btn-brand-1 btn-icon-load hover-up" to="/annonces">Voir plus d'Annonces</Link>
                  </div>

                </div>
              </section>
              <section className="section-box mt-50">
                <div className="post-loop-grid">
                  <div className="container">
                    <div className="row mt-70">
                      <div className="col-lg-6 col-md-12 col-sm-12" style={{position: "relative"}}>
                          <img src="./assets/images/yaako4.png" height={625} alt="joxBox" />
                          {/*<PlayArrowIcon*/}
                          {/*      // onClick={PreviousImage}*/}
                          {/*      style={{*/}
                          {/*          backgroundColor: "red",*/}
                          {/*          borderRadius: "50px",*/}
                          {/*          position: "absolute",*/}
                          {/*          cursor: "pointer",*/}
                          {/*          fontSize: "5rem",*/}
                          {/*          color: "white",*/}
                          {/*          top: "40%",*/}
                          {/*          left: "50.5%",*/}
                          {/*          "&:hover": {*/}
                          {/*              backgroundColor: "green"*/}
                          {/*          }*/}
                          {/*      }}*/}
                          {/*  />*/}
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        {/*<h3 className=""><b>S</b>olidarité, <b>E</b>ntraide, <b>S</b>outient</h3>*/}
                        <div className="">
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                                    style={{fontWeight: 'bold'}}
                                  >
                                    Qu'est-ce que Yaako ?
                                  </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <strong>Yaako est une application gratuite.</strong> qui facilite la publication et le partage de faire-part de décès avec la famille, les amis fidèles et la communauté, que ce soit localement ou à l'international. Elle offre également une plateforme sécurisée pour les contributions financières, apportant un soutien essentiel dans ces moments difficiles.
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                  <button className="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                                    style={{fontWeight: 'bold'}}
                                  >
                                    A qui est destiné Yaako ?
                                  </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <strong>Yaako est conçue pour tout le monde. </strong> L'application peut être utilisée pour publier des avis de décès, consulter des annonces, poster des messages de réconfort ou apporter un soutien financier à des proches. Bien que l'accès soit gratuit, certains services spécifiques sont payants
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                                    style={{fontWeight: 'bold'}}
                                  >
                                    Comment utiliser Yaako ?
                                  </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <strong>
                                      Pour commencer, téléchargez l'application sur votre smartphone ou accédez-y via notre site internet. La première étape consiste à créer un compte utilisateur.
                                    </strong>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFour">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
                                    style={{fontWeight: 'bold'}}
                                  >
                                    Comment publier un faire-part de décès ?
                                  </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <strong>
                                      Une fois votre compte créé, la publication d'un faire-part de décès est gratuite. L'application vous assiste tout au long de ce processus pour faciliter la mise en ligne de votre annonce.
                                    </strong>
                              
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFive">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"
                                    style={{fontWeight: 'bold'}}
                                  >
                                    Comment ouvrir une cagnotte pour un faire-part de décès ?
                                  </button>
                                </h2>
                                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <strong>La création d'une cagnotte est gratuite mais nécessite un compte mobile money et une pièce d'identité pour garantir la sécurité des transactions. </strong>                                     
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSix">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"
                                    style={{fontWeight: 'bold'}}
                                  >
                                    Comment contribuer à une cagnotte ?
                                  </button>
                                </h2>
                                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <strong>
                                    Vous pouvez contribuer à une cagnotte directement via l'application en utilisant votre portefeuille mobile money ou une carte bancaire (Mastercard, VISA ...) ce qui assure transparence et sécurité des transactions.
                                    </strong>                                     
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSeven">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"
                                    style={{fontWeight: 'bold'}}
                                  >
                                    Quels sont les opérateurs mobile money partenaires ?
                                  </button>
                                </h2>
                                <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <strong> 
                                        Nos partenaires incluent Orange Money, MTN Money, Moov Money et Wave. Les transactions peuvent également être réalisées par carte bancaire.
                                    </strong> 
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingHeight">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHeight" aria-expanded="false" aria-controls="collapseHeight"
                                    style={{fontWeight: 'bold'}}
                                  >
                                    Quels est le niveau de sécurité de Yaako ?
                                  </button>
                                </h2>
                                <div id="collapseHeight" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <strong> 
                                      Yaako utilise des technologies de sécurité avancées à tous les niveaux pour protéger vos données personnelles et vos transactions, assurant une protection optimale.
                                    </strong> 
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                        {/* <div className="mt-30 text-center"><a className="btn btn-brand-1" href="/about">A Propos</a></div> */}
                    </div>
                  </div>
                </div>
              </section>
            </main>
            <Footer />
        </Fragment>
    )

}

export default Home;