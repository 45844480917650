import React, {Fragment} from "react";
// import PaiementPage from "./html/Paiment.html"

function PaiementSuccess() {

  return (
        <Fragment>
            <div>
                  <iframe
                      // src="./html/Paiment.html"
                      // src='https://www.youtube.com/watch?v=qcnrdQmz2gA'
                      width="100%"
                      height="1000px"
                      allow="autoplay"
                      title="Paiement CINECTPAY"
                  />
            </div>
        </Fragment>
    )

}

export default PaiementSuccess;


//
// function PaiementSuccess(CinetPay) {
//     // let CinetPay;
//     const checkout = () =>{
//         CinetPay.setConfig = ({
//             apikey: '4059741266541658da2c380.54147682',//   YOUR APIKEY
//             site_id: '5866540',//YOUR_SITE_ID
//             notify_url: 'http://localhost:3000/notify/',
//             mode: 'PRODUCTION'
//         });
//         CinetPay.getCheckout = ({
//             transaction_id: Math.floor(Math.random() * 100000000).toString(),
//             amount: 100,
//             currency: 'XOF',
//             channels: 'ALL',
//             description: 'Test de paiement',
//              //Fournir ces variables pour le paiements par carte bancaire
//             customer_name:"Joe",//Le nom du client
//             customer_surname:"Down",//Le prenom du client
//             customer_email: "down@test.com",//l'email du client
//             customer_phone_number: "0748566846",//l'email du client
//             customer_address : "BP 0024",//addresse du client
//             customer_city: "Abidjan",// La ville du client
//             customer_country : "CI",// le code ISO du pays
//             customer_state : "CI",// le code ISO l'état
//             customer_zip_code : "225", // code postal
//
//         });
//         CinetPay.waitResponse = (function(data) {
//             if (data.status == "REFUSED") {
//                 if (alert("Votre paiement a échoué")) {
//                     window.location.reload();
//                 }
//             } else if (data.status == "ACCEPTED") {
//                 if (alert("Votre paiement a été effectué avec succès")) {
//                     window.location.reload();
//                 }
//             }
//         });
//         CinetPay.onError = (function(data) {
//             console.log(data);
//         });
//     }
//
//
//     return (
//         <Fragment>
//             <div className="sdk">
//                 <h1>SDK SEAMLESS</h1>
//                 <button onClick={checkout}>
//                     Contribuer
//                 </button>
//             </div>
//         </Fragment>
//     );
// }

// export default PaiementSuccess;



// var perf =require('./html/Paiment.html');
//
// class PaiementSuccess extends React.Component {
//    render(){
//       return (
//          <iframe src={perf}></iframe>   /* like this */
//       );
//    }
// }
// export default PaiementSuccess;