import React, {Fragment, useContext} from "react";
import Loader from "../assets/imgs/template/loading.gif";
import LogoImg from "../assets/imgs/logos/logo.png";
import {Link, useNavigate} from "react-router-dom";
import StateContext from "../../Context/StateContext";
import axios from "axios";
import DispatchContext from "../../Context/DispatchContext";
import BaseUrl from "../config/baseUrl";
// import BaseUrl from "./config/baseUrl";
const url = BaseUrl();

function Header() {
    const navigate = useNavigate();
    const GlobalState = useContext(StateContext);
    const GlobalDispatch = useContext(DispatchContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    async function HandleLogout() {
        setAnchorEl(null);
        const confirmLogout = window.confirm('Vous serez déconnectez, Continuer ?');
        if (confirmLogout) {
            try {
                const response = await axios
                .post(url+'/api-auth-djoser/token/logout/',
                        GlobalState.userToken ,
                        {
                            headers:{ Authorization: "Token ".concat(GlobalState.userToken)}
                        }
                    );
                    console.log(response);
                    GlobalDispatch({type:"logout"});
                    // setOpenSnack(true)
                    navigate("/home");
            }catch (e) {
                console.log(e.response)
            }
        }

    }

    function ButtonDisplay() {
        if(GlobalState.userIsConnecter) {
        return (
            <a className="btn btn-danger btn-shadow ml-40 hover-up" href="#" style={{fontWeight: 'bolder'}} onClick={HandleLogout}>Déconnexion</a>
        )
        } else if (!GlobalState.userIsConnecter) {
        return (
             <a className="btn btn-default btn-shadow ml-40 hover-up" href="/inscription" style={{fontWeight: 'bolder'}}>Inscription</a>
        )
        }
    }

    return (
        <Fragment>
            <div className="modal fade" id="ModalApplyJobForm" tabIndex="-1" aria-hidden="true">
                 <div className="modal-dialog modal-lg">
                    <div className="modal-content apply-job-form">
                      <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                      <div className="modal-body pl-30 pr-30 pt-50">
                        <div className="text-center">
                          <p className="font-sm text-brand-2">Faire un Don </p>
                          <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">Formulaire de Contrition</h2>
                          <p className="font-sm text-muted mb-30">
                            <div className="btn btn-apply-icon btn-primary btn-apply hover-up" data-bs-toggle="modal" data-bs-target="#ModalDonIndividuelForm">Don Individuel</div>
                            <div className="btn btn-apply-icon btn-apply btn-info hover-up" data-bs-toggle="modal" data-bs-target="#ModalDonGroupeForm">Don Groupé</div>
                            {/*<button type="button" className="btn btn-primary">Don Individuel</button>*/}
                            {/*<button type="button" className="btn btn-secondary">Don Groupé</button>*/}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
             </div>
            <div className="modal fade" id="ModalDonIndividuelForm" tabIndex="-1" aria-hidden="true">
                 <div className="modal-dialog modal-lg">
                    <div className="modal-content apply-job-form">
                      <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                      <div className="modal-body pl-30 pr-30 pt-50">
                        <div className="text-center">
                          <p className="font-sm text-brand-2">Formulaire Don Individuel </p>
                          <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">Contribué</h2>
                        </div>
                        <form className="login-register text-start mt-20 pb-30" action="#">
                          {/*<div className="form-group">*/}
                          {/*  <label className="form-label" htmlFor="input-1">Préciser le montant Ici *</label>*/}
                          {/*  <input className="form-control" id="input-1" type="number" required="" name="montant" placeholder="Somme en Chiffre ici" />*/}
                          {/*</div>*/}
                            <div className="form-group">
                                <button
                                    role="link"
                                    onClick={() =>navigate(
                                        '/contribution'
                                    )}
                                    className="btn btn-default hover-up w-100" type="submit"
                                        name="login">Contribuer
                                </button>
                                {/*<button*/}
                                {/*    role="link"*/}
                                {/*    onClick={() =>navigate(*/}
                                {/*        '/contribution'*/}
                                {/*    )}*/}
                                {/*>*/}
                                {/*    Visit*/}
                                {/*</button>*/}
                            </div>
                        </form>
                      </div>
                    </div>
                 </div>
            </div>

            <div className="modal fade" id="ModalPromesse" tabIndex="-1" aria-hidden="true">
                 <div className="modal-dialog modal-lg">
                    <div className="modal-content apply-job-form">
                      <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                      <div className="modal-body pl-30 pr-30 pt-50">
                        <div className="job-overview">
                            <h5 className="border-bottom pb-15 mb-30 text-justify lh-lg text-center">Promesse Koliac</h5>
                              <p>
                                Découvrez KoliaC, votre allié inébranlable pour des gestes de solidarité en toute confiance.
                                Nous garantissons une sécurité absolue pour chaque contribution, une fiabilité inégalée pour que votre aide parvienne toujours à destination,
                                  et une transparence totale sur l'utilisation de vos ressources. Avec KoliaC,
                                  chaque acte de bonté est suivi en temps réel, et vous pouvez collaborer en toute confiance pour créer un impact concret.
                                  Soyez assurés que votre générosité est entre de bonnes mains avec KoliaC. Rejoignez-nous pour construire un monde où la solidarité s'épanouit en toute sécurité et transparence.
                              </p>
                        </div>
                      </div>
                    </div>
                  </div>
             </div>
            <div className="modal fade" id="ModalSecurite" tabIndex="-1" aria-hidden="true">
                 <div className="modal-dialog modal-lg">
                    <div className="modal-content apply-job-form">
                      <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                      <div className="modal-body pl-30 pr-30 pt-50">
                        <div className="job-overview">
                            <h5 className="border-bottom pb-15 mb-30 text-justify lh-lg text-center">Sécurité sur Koliac</h5>
                              <p>
                                Découvrez KoliaC, votre allié inébranlable pour des gestes de solidarité en toute confiance.
                                Nous garantissons une sécurité absolue pour chaque contribution, une fiabilité inégalée pour que votre aide parvienne toujours à destination,
                                  et une transparence totale sur l'utilisation de vos ressources. Avec KoliaC,
                                  chaque acte de bonté est suivi en temps réel, et vous pouvez collaborer en toute confiance pour créer un impact concret.
                                  Soyez assurés que votre générosité est entre de bonnes mains avec KoliaC. Rejoignez-nous pour construire un monde où la solidarité s'épanouit en toute sécurité et transparence.
                              </p>
                        </div>
                      </div>
                    </div>
                  </div>
             </div>

            <div className="modal fade" id="ModalDonGroupeForm" tabIndex="-1" aria-hidden="true">
                 <div className="modal-dialog modal-lg">
                    <div className="modal-content apply-job-form">
                      <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                      <div className="modal-body pl-30 pr-30 pt-50">
                        <div className="text-center">
                          <p className="font-sm text-brand-2">Formulaire Don Groupé </p>
                          <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">Contribué</h2>
                        </div>
                        <form className="login-register text-start mt-20 pb-30" action="#">
                          <div className="row">
                              <div className="form-group col-lg-8">
                                <label className="form-label" htmlFor="input-1">Nom et Prénoms du Membre</label>
                                <input className="form-control" id="input-1" type="text" required="" name="montant" placeholder="Nom et Prénoms" />
                              </div>
                              <div className="form-group col-lg-4">
                                <label className="form-label" htmlFor="input-1">Contact</label>
                                <input className="form-control" id="input-1" type="tel" required="" name="montant" placeholder="Contact " />
                              </div>
                          </div>
                          <div className="form-group">
                            <button className="btn btn-default hover-up w-100" type="submit" name="login">Ajouter</button>
                          </div>
                        </form>

                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Nom et prénoms</th>
                              <th scope="col">Contact</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>Mark</td>
                              <td>0101010101</td>
                            </tr>
                            <tr>
                              <th scope="row">1</th>
                              <td>Mark</td>
                              <td>0101010101</td>
                            </tr>
                            <tr>
                              <th scope="row">1</th>
                              <td>Mark</td>
                              <td>0101010101</td>
                            </tr>
                          </tbody>
                        </table>
                        <form className="login-register text-start mt-20 pb-30" action="#">
                          <div className="form-group">
                            <label className="form-label" htmlFor="input-1">Préciser le montant Ici *</label>
                            <input className="form-control" id="input-1" type="number" required="" name="montant" placeholder="Somme en Chiffre ici" />
                          </div>
                          <div className="form-group">
                            <button className="btn btn-default hover-up w-100" type="submit" name="login">Contribuer</button>
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>
             </div>

            <header className="header sticky-bar">
              <div className="container">
                <div className="main-header">
                  <div className="header-left">
                    <div className="header-logo">
                        <a className="d-flex" href="/home">
                            <img alt="jobBox" src={LogoImg} style={{width: "450px", height: "150px", borderRadius:"25px"}} />
                        </a>
                    </div>
                  </div>
                  <div className="header-nav">
                    <nav className="nav-main-menu">
                      <ul className="main-menu">
                        <li>
                          <a className="active" href="/home">Home</a>
                        </li>
                        <li className="">
                          <a href="/annonces">Annonces</a>
                        </li>
                        {/*<li className="">*/}
                        {/*  <a href="/about">A Propos</a>*/}
                        {/*</li>*/}
                        {GlobalState.userIsConnecter ?
                            <li className="dashboard">
                                <a href="/dashboard">Profile</a>
                            </li> : ""
                        }
                      </ul>
                    </nav>
                    <div className="burger-icon burger-icon-white"><span className="burger-icon-top"></span><span className="burger-icon-mid"></span><span className="burger-icon-bottom"></span></div>
                  </div>
                  <div className="header-right">
                    <div className="block-signin">
                      {/*<a className="text-link-bd-btom hover-up" href="/inscription">Inscription</a>*/}
                      {/*/!*<a className="btn btn-default btn-shadow ml-40 hover-up" href="page-signin.html">Inscription</a>*!/*/}
                      {ButtonDisplay()}
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
              <div className="mobile-header-wrapper-inner">
                <div className="mobile-header-content-area">
                  <div className="perfect-scroll">
                    <div className="mobile-menu-wrap mobile-header-border">
                      <nav className="nav-main-menu">
                      <ul className="main-menu">
                        <li>
                          <a className="active" href="/">Accueil</a>
                        </li>
                        <li className="">
                          <a href="/annonces">Annonces</a>
                        </li>
                        <li className="">
                          <a href="/about">A Propos</a>
                        </li>
                        {GlobalState.userIsConnecter ?
                            <li className="dashboard">
                                <a href="/dashboard">Profile</a>
                            </li> : ""
                        }
                        {/*<Link to="/inscription" className="dashboard">*/}
                        {/*  <a href="/inscription" target="_blank">Inscription</a>*/}
                        {/*</Link>*/}
                      </ul>
                    </nav>
                    </div>
                    <div className="mobile-account">
                      <h6 className="mb-10">Your Account</h6>
                      <ul className="mobile-menu font-heading">
                        <li><a href="#">Profile</a></li>
                        <li><a href="#">Work Preferences</a></li>
                        <li><a href="#">Account Settings</a></li>
                        <li><a href="#">Go Pro</a></li>
                        <li><a href="page-signin.html">Sign Out</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
              <div className="mobile-header-wrapper-inner">
                <div className="mobile-header-content-area">
                  <div className="perfect-scroll">
                    <div className="mobile-menu-wrap mobile-header-border">
                      <nav className="nav-main-menu">
                      <ul className="main-menu">
                            <li>
                              <a className="active" href="/">Accueil</a>
                            </li>
                            <li className="">
                              <a href="/annonces">Annonces</a>
                            </li>
                            <li className="">
                              <a href="/about">A Propos</a>
                            </li>
                            {GlobalState.userIsConnecter ?
                                <li className="dashboard">
                                    <a href="/dashboard">Profile</a>
                                </li> : ""
                            }
                      </ul>
                    </nav>
                    </div>
                    <div className="mobile-account">
                      <h6 className="mb-10">Your Account</h6>
                      <ul className="mobile-menu font-heading">
                        <li><a href="#">Profile</a></li>
                        <li><a href="#">Work Preferences</a></li>
                        <li><a href="#">Account Settings</a></li>
                        <li><a href="#">Go Pro</a></li>
                        <li><a href="page-signin.html">Sign Out</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </Fragment>
    )

}
export default Header;