import React, {Fragment, useEffect, useState} from "react";
import Swal from "sweetalert2";
import {Link, useNavigate} from "react-router-dom";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Header from "./_partials/Header";
import {useImmerReducer} from "use-immer";
import axios from "axios";

import BaseUrl from "./config/baseUrl";

const url = BaseUrl()

function Inscription() {
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();

    const initialState = {
        nomValue: "",
        contactValue: "",
        userNameValue: "",
        emailValue: "",
        password1: "",
        password2: "",
        sendRequest: 0,
    }

    function ReducerFunction (draft, action) {
        switch(action.type) {
          case "catchNomChange":
            draft.nomValue = action.nomChosen;
          break;

          case "catchContactChange":
            draft.phone = action.contactChosen;
          break;

          case "catchUsernameChange":
            draft.userNameValue = action.usernameChosen;
          break;

          case "catchEmailChange":
            draft.emailValue = action.emailChosen;
          break;

          case "catchPasswordChange":
            draft.password1 = action.passwordChosen;
          break;

          case "catchPassword2Change":
            draft.password2 = action.password2Chosen;
          break;

          case "changeSendRequest":
          draft.sendRequest = draft.sendRequest + 1;
          break;
        }
    }
    const [state, dispatch] =useImmerReducer(ReducerFunction, initialState)

        //OnSubmit Function du Formulaire
    function FormSubmit (e) {
        e.preventDefault();
        console.log("formulaire transmis !!!")
        dispatch({type: 'changeSendRequest'})
    }

    useEffect(() => {
        if (state.sendRequest) {
            const source = axios.CancelToken.source();
                async function SignUp() {
                    Swal.fire({
                        title: 'Inscription...',
                        html: 'Veillez patientez...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                          Swal.showLoading()
                        },
                    });
                    try{
                        const response = await axios
                            // .post('http://127.0.0.1:8000/api-auth-djoser/users/',
                            .post(url+'/inscription/',
                            {
                                nom: state.nomValue,
                                contact1: phone,
                                username: state.userNameValue,
                                email: state.emailValue,
                                password1: state.password1,
                                password2: state.password2,
                            },
                            {
                                cancelToken: source.token
                            }
                            );
                            Swal.fire({
                              title: 'FELICITATION !',
                              html: "Bienvenue <b>"+state.userNameValue+"</b> Profile enregistré avec Succès",
                              icon: 'success',
                              showCancelButton: false,
                              confirmButtonColor: '#3085d6',
                              confirmButtonText: 'OK'
                            })
                            console.log(response.data);
                            navigate('/connexion')
                    } catch (error) {
                        console.log(error.response);
                    }
                }
                SignUp();
                return () => {
                    source.cancel();
                };
            }
    }, [state.sendRequest]);

    return (
        <Fragment>
            <Header />
            <main className="main">
              <section className="login-register">
                <div className="container">
                  <div className="row login-register-cover">
                    <div className="col-lg-8 col-md-6 col-sm-12 mx-auto">
                      <div className="text-center">
                        <h2 className="mt-10 mb-5 text-brand-1">Formulaire d'Inscription</h2>
                        <div className="divider-text-center"><span></span></div>
                      </div>
                      <form onSubmit={FormSubmit} className="login-register text-start" action="#">
                        <div className="form-group">
                          <label className="form-label" htmlFor="input-1">Nom et prénoms <span className="text-danger">*</span></label>
                            <input
                                id="nom"
                                style={{padding: "5px", borderRadius: "15px"}}
                                type="text"
                                className="form-control"
                                placeholder="Nom & Prénoms"
                                required=""
                                value={state.nomValue}
                                onChange={(e)=>dispatch({
                                type:'catchNomChange',
                                nomChosen: e.target.value})}
                            />
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="input-1">Contact <span className="text-danger">*</span></label>
                          <PhoneInput
                            id="contact1"
                            style={{width: "100%", borderRadius: "15px"}}
                            defaultCountry="ci"
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="input-2">Email <span className="text-danger">*</span></label>
                          <input
                              id="email"
                              style={{padding: "5px", borderRadius: "15px"}}
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              required=""
                              value={state.emailValue}
                              onChange={(e)=>dispatch({
                                type:'catchEmailChange', emailChosen: e.target.value
                              })}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="input-3">Nom Utilisateur <span className="text-danger">*</span></label>
                          <input
                              id="username"
                              style={{padding: "5px", borderRadius: "15px"}}
                              type="text"
                              className="form-control"
                              placeholder="Nom Utilisateur "
                              required=""
                              value={state.userNameValue}
                              onChange={(e)=>dispatch({
                                type:'catchUsernameChange',
                                usernameChosen: e.target.value
                              })}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="input-4">Mot de Passe <span className="text-danger">*</span></label>
                          <input
                              style={{padding: "5px", borderRadius: "15px"}}
                              className="form-control"
                              id="password1"
                              type="password"
                              required=""
                              placeholder="Mot de Passe"
                              value={state.password}
                              onChange={(e)=>dispatch({
                                type:'catchPasswordChange',
                                passwordChosen: e.target.value
                              })}
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="input-5">Confirmer Mot de Passe <span className="text-danger">*</span></label>
                          <input
                              style={{padding: "5px", borderRadius: "15px"}}
                              className="form-control"
                              id="password2"
                              type="password"
                              required=""
                              placeholder="Confirmer Mot de Passe"
                              value={state.password2}
                              onChange={(e)=>dispatch({
                                    type:'catchPassword2Change',
                                    password2Chosen: e.target.value
                              })}
                          />
                        </div>
                        {/*<div className="login_footer form-group d-flex justify-content-between">*/}
                        {/*  <label className="cb-container">*/}
                        {/*    <input type="checkbox" /><span className="text-small">Agree our terms and policy</span><span className="checkmark"></span>*/}
                        {/*  </label><a className="text-muted" href="page-contact.html">Lean more</a>*/}
                        {/*</div>*/}
                        <div className="form-group">
                          <button className="btn btn-brand-1 hover-up w-100" type="submit" style={{borderRadius: "32px"}}>Inscription</button>
                        </div>
                        <div className="col-12 text-center">
                            <p className="mb-0 mt-3 text-center"><small className="text-dark me-2">Déjà Inscrit ?</small> <Link to="/Connexion" className="text-Info fw-semibold" style={{ fontSize: "22px" }}> Connexion</Link></p>
                        </div>
                      </form>
                      <div className="text-center">
                          <p className="mb-0">© {new Date().getFullYear()} Yaako. Copyright <i className="mdi mdi-heart text-danger"></i> <a href="#" target="_blank" className="text-reset">Tous Droits Réservés</a>.</p>
                      </div>
                    </div>
                    {/* <div className="img-1 d-none d-lg-block">
                        <img className="shape-1" src="assets/imgs/page/login-register/img-1.svg" alt="JobBox" />
                    </div>
                    <div className="img-2">
                        <img src="assets/imgs/page/login-register/img-2.svg" alt="JobBox" />
                    </div> */}
                  </div>
                </div>
              </section>
            </main>
        </Fragment>
    )

}

export default Inscription;