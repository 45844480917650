import React, {Fragment} from "react";
import Header from "./_partials/Header";
import Footer from "./_partials/Footer";
import HeaderIndex from "./_partials/HeaderIndex";

function Pricing() {
    return (
        <Fragment>
            <HeaderIndex />
            <main className="main">
                <section className="section-box">
                    <div className="breacrumb-cover">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h2 className="mb-10"> Offres</h2>
                                    <p className="font-lg color-text-paragraph-2">Des offres adaptée a vos Besoins</p>
                                </div>
                                <div className="col-lg-6 text-lg-end">
                                    <ul className="breadcrumbs mt-40">
                                        <li><a className="home-icon" href="/">Accueil</a></li>
                                        <li>Offres</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-box mt-90">
                    <div className="container">
                        <h2 className="text-center mb-15 wow animate__animated animate__fadeInUp">Nos Offres</h2>
                        <div
                            className="font-lg color-text-paragraph-2 text-center wow animate__animated animate__fadeInUp">
                            Choisissez l'offre qui vous convient
                        </div>
                        <div className="max-width-price">
                            <div className="block-pricing mt-70">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-6 col-md-6 wow animate__animated animate__fadeInUp"
                                         data-wow-delay=".1s">
                                        <div className="box-pricing-item">
                                            <h3>Standard</h3>
                                            <div className="box-info-price"><span
                                                className="text-price color-brand-2">00</span><span
                                                className="text-month">/2 Semaine</span></div>
                                            <div className="border-bottom mb-30">
                                                <p className="text-desc-package font-sm color-text-paragraph mb-30">Offre Standard / Gratuite</p>
                                            </div>
                                            <ul className="list-package-feature">
                                                <li>Unlimited updates</li>
                                                <li>Custom designs &amp; features</li>
                                                <li>Custom permissions</li>
                                                <li>Custom instructors</li>
                                                <li>Free support ticket</li>
                                            </ul>
                                            <div><a className="btn btn-border" href="/init_annonce">Continuer</a></div>
                                            {/*<div><a className="btn btn-border" href="/add_annonce">Continuer</a></div>*/}
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 wow animate__animated animate__fadeInUp"
                                         data-wow-delay=".2s">
                                        <div className="box-pricing-item">
                                            <h3>Prestige</h3>
                                            <div className="box-info-price"><span
                                                className="text-price for-month display-month">20000</span><span
                                                className="text-month">/Mois</span></div>
                                            <div className="border-bottom mb-30">
                                                <p className="text-desc-package mb-30">Offre prestige</p>
                                            </div>
                                            <ul className="list-package-feature">
                                                <li>Unlimited updates</li>
                                                <li>Custom designs &amp; features</li>
                                                <li>Custom permissions</li>
                                                <li>Custom instructors</li>
                                                <li>Free support ticket</li>
                                            </ul>
                                            <div><a className="btn btn-border" href="/annonce_privilege">Continuer</a></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 wow animate__animated animate__fadeInUp"
                                         data-wow-delay=".4s">
                                        <div className="box-pricing-item">
                                            <h3>Prémium</h3>
                                            <div className="box-info-price"><span
                                                className="text-price for-month display-month">50000</span><span
                                                className="text-month">/Trimestre</span></div>
                                            <div className="border-bottom mb-30">
                                                <p className="text-desc-package mb-30">Offre Premium</p>
                                            </div>
                                            <ul className="list-package-feature">
                                                <li>Unlimited updates</li>
                                                <li>Custom designs &amp; features</li>
                                                <li>Custom permissions</li>
                                                <li>Custom instructors</li>
                                                <li>Free support ticket</li>
                                            </ul>
                                            <div><a className="btn btn-border" href="/annonce_premium">Continuer</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-box mt-90 mb-50">
                    <div className="container">
                        <h2 className="text-center mb-15 wow animate__animated animate__fadeInUp">
                            Questions fréquemment posées
                        </h2>
                        <div
                            className="font-lg color-text-paragraph-2 text-center wow animate__animated animate__fadeInUp">Aliquam
                            a augue suscipit, luctus neque purus ipsum neque dolor<br
                                className="d-none d-lg-block"/> primis a libero tempus, blandit and cursus varius and
                            magnis sapien
                        </div>
                        <div className="row mt-50">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">
                                    <h4 className="mb-20">I have promotional or discount code?</h4>
                                    <p className="font-sm mb-20 color-text-paragraph">Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit. Duis id nibh vitae quam blandit venenatis. Duis
                                        vehicula magna ut neque tempus tristique. Nam venenatis turpis euismod arcu
                                        aliquet finibus. Vivamus nec vulputate ex, vitae condimentum ante. Suspendisse
                                        metus metus, laoreet nec arcu vel, venenatis cursus libero.</p><a
                                    className="link-arrow" href="#">Keep Reading</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">
                                    <h4 className="mb-20">Where is my order? Quisque molestie</h4>
                                    <p className="font-sm mb-20 color-text-paragraph">Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit. Duis id nibh vitae quam blandit venenatis. Duis
                                        vehicula magna ut neque tempus tristique. Nam venenatis turpis euismod arcu
                                        aliquet finibus. Vivamus nec vulputate ex, vitae condimentum ante. Suspendisse
                                        metus metus, laoreet nec arcu vel, venenatis cursus libero.</p><a
                                    className="link-arrow" href="#">Keep Reading</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">
                                    <h4 className="mb-20">How can I return an item purchased online?</h4>
                                    <p className="font-sm mb-20 color-text-paragraph">Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit. Duis id nibh vitae quam blandit venenatis. Duis
                                        vehicula magna ut neque tempus tristique. Nam venenatis turpis euismod arcu
                                        aliquet finibus. Vivamus nec vulputate ex, vitae condimentum ante. Suspendisse
                                        metus metus, laoreet nec arcu vel, venenatis cursus libero.</p><a
                                    className="link-arrow" href="#">Keep Reading</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">
                                    <h4 className="mb-20">What are the delivery types you use?</h4>
                                    <p className="font-sm mb-20 color-text-paragraph">Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit. Duis id nibh vitae quam blandit venenatis. Duis
                                        vehicula magna ut neque tempus tristique. Nam venenatis turpis euismod arcu
                                        aliquet finibus. Vivamus nec vulputate ex, vitae condimentum ante. Suspendisse
                                        metus metus, laoreet nec arcu vel, venenatis cursus libero.</p><a
                                    className="link-arrow" href="#">Keep Reading</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">
                                    <h4 className="mb-20">How can I pay for my purchases?</h4>
                                    <p className="font-sm mb-20 color-text-paragraph">Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit. Duis id nibh vitae quam blandit venenatis. Duis
                                        vehicula magna ut neque tempus tristique. Nam venenatis turpis euismod arcu
                                        aliquet finibus. Vivamus nec vulputate ex, vitae condimentum ante. Suspendisse
                                        metus metus, laoreet nec arcu vel, venenatis cursus libero.</p><a
                                    className="link-arrow" href="#">Keep Reading</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">
                                    <h4 className="mb-20">What are the delivery types you use?</h4>
                                    <p className="font-sm mb-20 color-text-paragraph">Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit. Duis id nibh vitae quam blandit venenatis. Duis
                                        vehicula magna ut neque tempus tristique. Nam venenatis turpis euismod arcu
                                        aliquet finibus. Vivamus nec vulputate ex, vitae condimentum ante. Suspendisse
                                        metus metus, laoreet nec arcu vel, venenatis cursus libero.</p><a
                                    className="link-arrow" href="#">Keep Reading</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section className="section-box mt-30 mb-40">*/}
                {/*    <div className="container">*/}
                {/*        <h2 className="text-center mb-15 wow animate__animated animate__fadeInUp">Our Happy*/}
                {/*            Customer</h2>*/}
                {/*        <div*/}
                {/*            className="font-lg color-text-paragraph-2 text-center wow animate__animated animate__fadeInUp">When*/}
                {/*            it comes to choosing the right web hosting provider, we know how easy it<br*/}
                {/*                className="d-none d-lg-block"/> is to get overwhelmed with the number.*/}
                {/*        </div>*/}
                {/*        <div className="row mt-50">*/}
                {/*            <div className="box-swiper">*/}
                {/*                <div className="swiper-container swiper-group-3 swiper">*/}
                {/*                    <div className="swiper-wrapper pb-70 pt-5">*/}
                {/*                        <div className="swiper-slide">*/}
                {/*                            <div className="card-grid-6 hover-up">*/}
                {/*                                <div className="card-text-desc mt-10">*/}
                {/*                                    <p className="font-md color-text-paragraph">Lorem ipsum dolor sit*/}
                {/*                                        amet, consectetur adipiscing elit. Sed vitae neque metus.*/}
                {/*                                        Vivamus consectetur ultricies commodo. Pellentesque at nisl sit*/}
                {/*                                        amet neque finibus egestas ut at magna. Cras tincidunt tortor*/}
                {/*                                        sed eros aliquam eleifend.</p>*/}
                {/*                                </div>*/}
                {/*                                <div className="card-image">*/}
                {/*                                    <div className="image">*/}
                {/*                                        <figure><img alt="jobBox"*/}
                {/*                                                     src="assets/imgs/page/about/user1.png"/></figure>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="card-profile">*/}
                {/*                                        <h6>Mark Adair</h6><span>Businessmen</span>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="swiper-slide">*/}
                {/*                            <div className="card-grid-6 hover-up">*/}
                {/*                                <div className="card-text-desc mt-10">*/}
                {/*                                    <p className="font-md color-text-paragraph">Lorem ipsum dolor sit*/}
                {/*                                        amet, consectetur adipiscing elit. Sed vitae neque metus.*/}
                {/*                                        Vivamus consectetur ultricies commodo. Pellentesque at nisl sit*/}
                {/*                                        amet neque finibus egestas ut at magna. Cras tincidunt tortor*/}
                {/*                                        sed eros aliquam eleifend.</p>*/}
                {/*                                </div>*/}
                {/*                                <div className="card-image">*/}
                {/*                                    <div className="image">*/}
                {/*                                        <figure><img alt="jobBox"*/}
                {/*                                                     src="assets/imgs/page/about/user2.png"/></figure>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="card-profile">*/}
                {/*                                        <h6>Mark Adair</h6><span>Businessmen</span>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="swiper-slide">*/}
                {/*                            <div className="card-grid-6 hover-up">*/}
                {/*                                <div className="card-text-desc mt-10">*/}
                {/*                                    <p className="font-md color-text-paragraph">Lorem ipsum dolor sit*/}
                {/*                                        amet, consectetur adipiscing elit. Sed vitae neque metus.*/}
                {/*                                        Vivamus consectetur ultricies commodo. Pellentesque at nisl sit*/}
                {/*                                        amet neque finibus egestas ut at magna. Cras tincidunt tortor*/}
                {/*                                        sed eros aliquam eleifend.</p>*/}
                {/*                                </div>*/}
                {/*                                <div className="card-image">*/}
                {/*                                    <div className="image">*/}
                {/*                                        <figure><img alt="jobBox"*/}
                {/*                                                     src="assets/imgs/page/about/user3.png"/></figure>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="card-profile">*/}
                {/*                                        <h6>Mark Adair</h6><span>Businessmen</span>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="swiper-pagination swiper-pagination3"></div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/*<section className="section-box mt-50 mb-20">*/}
                {/*    <div className="container">*/}
                {/*        <div className="box-newsletter">*/}
                {/*            <div className="row">*/}
                {/*                <div className="col-xl-3 col-12 text-center d-none d-xl-block"><img*/}
                {/*                    src="assets/imgs/template/newsletter-left.png" alt="joxBox"/></div>*/}
                {/*                <div className="col-lg-12 col-xl-6 col-12">*/}
                {/*                    <h2 className="text-md-newsletter text-center">New Things Will Always<br/> Update*/}
                {/*                        Regularly</h2>*/}
                {/*                    <div className="box-form-newsletter mt-40">*/}
                {/*                        <form className="form-newsletter">*/}
                {/*                            <input className="input-newsletter" type="text" value=""*/}
                {/*                                   placeholder="Enter your email here"/>*/}
                {/*                            <button*/}
                {/*                                className="btn btn-default font-heading icon-send-letter">Subscribe*/}
                {/*                            </button>*/}
                {/*                        </form>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="col-xl-3 col-12 text-center d-none d-xl-block"><img*/}
                {/*                    src="assets/imgs/template/newsletter-right.png" alt="joxBox"/></div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
            </main>
            <Footer />
        </Fragment>
    )

}

export default Pricing;