import React, {Fragment} from "react";
// import Header from "./_partials/Header";
import HeaderIndex from "./_partials/HeaderIndex";


function Index() {
    return (
        <Fragment>
            <body>
                <div id="preloader-active">
                  <div className="preloader d-flex align-items-center justify-content-center">
                    <div className="preloader-inner position-relative">
                      <div className="text-center"><img src="assets/imgs/template/loading.gif" alt="jobBox" /></div>
                    </div>
                  </div>
                </div>
                <HeaderIndex />
                <main className="main">
                  <section className="section-box mb-70">
                    <div className="banner-hero hero-1 banner-homepage5">
                      <div className="banner-inner">
                        <div className="row">
                          <div className="col-xl-7 col-lg-12">
                            <div className="block-banner">
                              <h1 className="heading-banner wow animate__animated animate__fadeInUp">Yaako,<br className="d-none d-lg-block" />Vous ne serez jamais seuls</h1>
                              <div className="banner-description mt-20 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">Soutenir un Proche dans les moment Douloureux </div>
                              <div className="mt-30">
                                {/*<a className="btn btn-default mr-15">Démarrer</a>*/}
                                  <a href="/home" className="btn btn-default mr-15">Démarrer</a>
                                {/*<a className="btn btn-border-brand-2">Learn more</a>*/}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-5 col-lg-12 d-none d-xl-block col-md-6">
                            <div className="banner-imgs">
                              <div className="banner-1 shape-1"><img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage5/yaako_resized.png" style={{borderRadius: "30px"}} /></div>
                              {/* <div className="banner-2 shape-2"><img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage5/banner2.png" /></div>
                              <div className="banner-3 shape-3"><img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage5/banner3.png" /></div>
                              <div className="banner-4 shape-3"><img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage5/banner4.png" /></div>
                              <div className="banner-5 shape-2"><img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage5/banner5.png" /></div>
                              <div className="banner-6 shape-1"><img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage5/banner6.png" /></div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <script src="assets/js/plugins/counterup.js"></script>
                </main>
            </body>
        </Fragment>
    )
}

export default Index;