import React, {useState, useEffect, Fragment, useContext} from "react";
import axios from "axios";
import Stepper from 'react-stepper-horizontal';
// React Leaflet Component
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet';
import { Icon } from 'leaflet';
import {useImmerReducer} from "use-immer";
import {useNavigate, useParams} from "react-router-dom";
import Header from "./_partials/Header";
import Loader from "./assets/imgs/template/loading.gif";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import EgliseIconPng from './assets/Mapicons/cathedral.png';
import CimetierePng from './assets/Mapicons/catholicgrave.png'
import HomeIconPng from './assets/Mapicons/home-2.png'
import annonces from "./Annonces";
import moment from "moment";

import BaseUrl from "./config/baseUrl";
import DispatchContext from "../Context/DispatchContext";
import Swal from "sweetalert2";
import StateContext from "../Context/StateContext";

const url = BaseUrl()

function DetailAnnonce() {
    //Create State to Store the data from Server
    const GlobalState = useContext(StateContext)
    const navigate = useNavigate();
    const [allpoints, setAllpoints] = useState([])
    const [allmessages, setAllMessages] = useState([])
    const params = useParams()
    const [dataIsloading, setDataIsLoading] = useState(true)
    const [open, setOpen] = React.useState(false)
    const shareUrl = url+`/annonces/${params.id}/`
    // const shareUrl = `baseUrl+"/annonces/annonces/"${params.id}/`

    const [messageData,setmessageData] = useState({
        'user': "",
        'annonce': "",
        'contenu': "",
    });

    const handleAddMessage=(event)=>{
      setmessageData({
        ...messageData,
        [event.target.name]:event.target.value
      });
    }

    const onPenModaMessage=()=>{
        {
            window.$('#ModalDonMessageForm').modal('show')
        }

    }

    const submitMessage=()=>{
          // setErrorM(Validation(producteurData));
          if( messageData.contenu !="" )
          {
            const _formData = new FormData();
            _formData.append('user', GlobalState.userId);
            _formData.append('annonce', state.annonceInfo.id);
            _formData.append('contenu', messageData.contenu);

            //console.log(_formData);

            //  Swal.fire({
            //   title: 'Enregistrement...',
            //   html: 'Veillez patientez...',
            //   allowEscapeKey: false,
            //   allowOutsideClick: false,
            //   didOpen: () => {
            //     Swal.showLoading()
            //   },
            // });
            try {
              axios.post(url+'/messages/add/',_formData).then((resp)=>{
                Swal.close()
                if(resp.data.bool)
                console.log(resp.data)
                {
                  window.$('#ModalDonMessageForm').modal('hide');
                  Swal.fire({
                    title: 'FELICITATION !',
                    html: " Message Ajouter avec Succès ",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                  })
                }
              })
            } catch (error) {
              console.log(error);
            }
          }
        }

    const EgliseIcon = new Icon({
        iconUrl: EgliseIconPng,
        iconSize: [40, 40]
    });

    const DomicileIcon = new Icon({
        iconUrl: HomeIconPng,
        iconSize: [40, 40]
    });

    const CimetiereIcon = new Icon({
        iconUrl: CimetierePng,
        iconSize: [40, 40]
    });

    const initialState = {
        mapInstance : null,
        annonceInfo: "",
        userInfo: "",
        annoncePoints : [],
        annonceMessages: [],
    }

    function ReducerFunction (draft, action) {
          switch(action.type) {
            case "catchUserInfo":
                draft.userInfo = action.userObjet
            break;
            case "catchAnnonceInfos":
                draft.annonceInfo = action.annonceObject;
            break;
            case "catchAnnoncePoints":
                draft.annoncePoints = action.emplacement_messages;
            break;
            case "catchAnnonceMessages":
                draft.annonceMessages = action.annonce_messages;
            break;
            case "getMap":
                draft.mapInstance = action.mapData;
            break;

          }
      }

    function TheMapComponent() {
      const map =useMap()
      dispatch({
          type: "getMap",
          mapData: map
      });
      return null;
    }

    const [state, dispatch] =useImmerReducer(ReducerFunction, initialState)

    //get Profile Infos | recuperer les Données du Profile de l'utilisateur a excécuter lors du Chargement de la page
    useEffect(()=> {
        if (state.pointInfo) {
            async function getUserInfos() {
            try {
                const response = await axios
                .get(url+`profiles/${state.pointInfo.auteur}/`);
                // console.log(response.data)
                dispatch({
                    type:"catchUserInfo",
                    profileObjet: response.data
                });

                // dispatch({type: 'loadingDone'})
            } catch (e) {
                console.log(e.response)
            }
        }
            getUserInfos()
        }
    }, [state.pointInfo])

    //get recuperer les Données de l'Annonce
    useEffect(()=> {
        async function getAnnonceInfos() {
            try {
                const response = await axios
                .get(url+`/annonces/${params.id}/`);
                // setAllpois(response.data.);
                setDataIsLoading(false)
                // console.log(response.data)
                dispatch({
                    type:"catchAnnonceInfos",
                    //on charge les Infos dans la Variable annonceObject
                    annonceObject: response.data,
                });
                dispatch({type: 'loadingDone'})
            } catch (e) {
                console.log(e.response)
            }
        }
        getAnnonceInfos()
    }, [])

    const pointPicteures = [
        state.annonceInfo.image1,
        state.annonceInfo.image2,
        state.annonceInfo.image3,
        state.annonceInfo.image4,
        state.annonceInfo.image5,
    ].filter((image)=> image !== null);

    const [currentImage, setCurrentImage] = useState(0)

    function NextImage() {
        if (currentImage === pointPicteures.length -1) {
            return setCurrentImage(0)
        } else {
            setCurrentImage(currentImage + 1)
        }
    }

    function PreviousImage() {
        if(currentImage === 0) {
            return setCurrentImage(pointPicteures.length -1)
        } else {
            setCurrentImage(currentImage - 1)
        }
    }

    // //from data from backend with axios using UseEffect
    // useEffect(() => {
    //     const source = axios.CancelToken.source();
    //     async function GetPoints() {
    //         try{
    //             const response = await axios
    //                 .get(
    //                     'http://127.0.0.1:8000/api/emplacements/',
    //                      { cancelToken: source.token }
    //                 );
    //             setAllpoints(response.data);
    //             console.log(response);
    //             setDataIsLoading(false)
    //         } catch (error) {
    //             console.log(error.response);
    //         }
    //     }
    //     GetPoints();
    //     return () => {
    //         source.cancel();
    //     };
    // }, []);
    //
    // //from data from backend with axios using UseEffect
    // useEffect(() => {
    //     const source = axios.CancelToken.source();
    //     async function GetMessages() {
    //         try{
    //             const response = await axios
    //                 .get(
    //                     'http://127.0.0.1:8000/api/messages/',
    //                      { cancelToken: source.token }
    //                 );
    //             setAllMessages(response.data);
    //             console.log(response.data);
    //             setDataIsLoading(false)
    //         } catch (error) {
    //             console.log(error.response);
    //         }
    //     }
    //     GetMessages();
    //     return () => {
    //         source.cancel();
    //     };
    // }, []);

    const date = new Date(state.annonceInfo.add_le)
    const FormattedDate = `${date.getDate() + 1}/${date.getMonth()}/${date.getFullYear()}`

    if (dataIsloading === true) {
      return (

      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="text-center"><img src={Loader} alt="jobBox" /></div>
          </div>
        </div>
      </div>
      );
    }


    return (
        <Fragment>
            <Header />
            <main className="main">
              <section className="section-box-2">
                <div className="container">
                    {pointPicteures.length > 0 ? (
                        <div className="banner-hero banner-image-single col-lg-8 offset-2" style={{position: "relative", marginTop: "1px"}}>
                            {pointPicteures.map((image, index) => {
                                return (
                                    <div key={index}>
                                        {index === currentImage ? <img src={image} width={30} height={500} alt="jobBox" /> : "" }
                                        {/*{index === currentImage ? <img src={image} width={50} height={500} alt="jobBox" /> : "" }*/}
                                    </div>
                                )
                            })}
                            <ArrowCircleLeftIcon
                                onClick={PreviousImage}
                                style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    fontSize: "3rem",
                                    color: "white",
                                    top: "45%",
                                    left: ".5%",
                                    "&:hover": {
                                        backgroundColor: "green"
                                    }
                                }}
                            />
                            <ArrowCircleRightIcon
                                onClick={NextImage}
                                style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    fontSize: "3rem",
                                    color: "white",
                                    top: "45%",
                                    right: ".5%",
                                    "&:hover": {
                                        backgroundColor: "green"
                                    }
                                }}
                            />
                        </div>
                    ): ("")}
                  <div className="row">
                    <div className="col-lg-8 col-md-12">
                      <h3>{state.annonceInfo.titre.toUpperCase()}</h3>
                      <div className="mt-0 mb-15">
                          {state.annonceInfo.categorie === "DECES" ?
                              <span className="lbl-hot" style={{backgroundColor: "red"}}><span style={{fontWeight:"bolder", fontSize: "14px"}}>{state.annonceInfo.categorie}</span></span> :
                              <span className="lbl-hot"><span style={{fontWeight:"bolder", fontSize: "14px"}}>{state.annonceInfo.categorie}</span></span>
                          }
                          <br/>
                          Survenu le : <span className="card-time" style={{fontWeight: "Bold", fontSize: "16px", color: 'black'}}> {state.annonceInfo.date}</span>
                      </div>
                    </div>
                      <div className="col-lg-4 col-md-12 text-lg-end">
                          <div
                              role="link"
                              onClick={() => navigate(
                                  '/contribution'
                              )}
                              className="btn btn-apply-icon btn-apply btn-apply-big hover-up"
                               data-bs-toggle="modal">Contribuer
                          </div>
                          {/*<button*/}
                          {/*    role="link"*/}
                          {/*    onClick={() => navigate(*/}
                          {/*        '/contribution'*/}
                          {/*    )}*/}
                          {/*    className="btn btn-default hover-up w-100" type="submit"*/}
                          {/*    name="login">Contribuer*/}
                          {/*</button>*/}
                      </div>
                  </div>
                    <div className="border-bottom pt-10 pb-10"></div>
                </div>
              </section>
                <section className="section-box mt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                                {state.annonceInfo.faire_part ?
                                    <div className="job-overview">
                                        <h5 className="border-bottom pb-15 mb-30 text-justify lh-1">Faire part</h5>
                                        <p 
                                            // dangerouslySetInnerHTML={{__html: state.annonceInfo.faire_part}}
                                            dangerouslySetInnerHTML={{ __html: state.annonceInfo.faire_part.replace(/\n/g, "<br />") }}
                                        />
                                        {/* <p>
                                            {state.annonceInfo.faire_part}
                                        </p> */}
                                    </div> : ""
                                }

                                {state.annonceInfo.programme ?
                                    <div className="job-overview">
                                        <h5 className="border-bottom pb-15 mb-30 text-justify lh-lg">Programme</h5>
                                        <p 
                                            // dangerouslySetInnerHTML={{__html: state.annonceInfo.programme}}
                                            dangerouslySetInnerHTML={{ __html: state.annonceInfo.programme.replace(/\n/g, "<br />") }}
                                        />
                                    </div> : ""
                                }

                                {/*<div className="job-overview">*/}
                                {/*  <h5 className="border-bottom pb-15 mb-30">Programme des Obsèques</h5>*/}
                                {/*    <p>*/}
                                {/*        <Stepper steps={[*/}
                                {/*            {title: 'du 11 - 13/02/2023: Condoléances au domicile du défunt:sis à la riviera'},*/}
                                {/*            {title: '16/02/2023: Veillée d’hommage au domicile du défunt 19h à 21h00 riviera'},*/}
                                {/*            {title: '18/02/2023: levée de corps à IVOSEP, salle Félix Houphouët Boigny, de 10h30 à 11h30'},*/}
                                {/*            {title: '18/02/2023: Messe Orthodoxe au domicile Messe Orthodoxe au domicile & inhumation au cimetière de Williamsville, (Carré d’Adjamé)'}*/}
                                {/*        ]} activeStep={ 3 }*/}
                                {/*        />*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                                <div className="single-apply-jobs">
                                    <div className="row align-items-center">
                                        <div className="col-md-5">
                                            <div className="btn btn-apply-icon btn-apply btn-apply-big hover-up"
                                                 data-bs-toggle="modal" data-bs-target="#ModalDonMessageForm">Laisser Un
                                                Message
                                            </div>
                                        </div>
                                        <div className="col-md-7 text-lg-end social-share">
                                            <h6 className="color-text-paragraph-2 d-inline-block d-baseline mr-10">Partager
                                                Sur : </h6>
                                            {/*<a className="mr-5 d-inline-block d-middle" href="#">*/}
                                            <EmailShareButton url={shareUrl} className="mr-5 d-inline-block d-middle">
                                                <EmailIcon size={40} round={true}/>
                                            </EmailShareButton>
                                            <FacebookShareButton url={shareUrl}
                                                                 className="mr-5 d-inline-block d-middle">
                                                <FacebookIcon size={40} round={true}/>
                                            </FacebookShareButton>
                                            <TwitterShareButton url={shareUrl} className="mr-5 d-inline-block d-middle">
                                                <TwitterIcon size={40} round={true}/>
                                            </TwitterShareButton>
                                            <LinkedinShareButton url={shareUrl}
                                                                 className="mr-5 d-inline-block d-middle">
                                                <LinkedinIcon size={40} round={true}/>
                                            </LinkedinShareButton>
                                            <WhatsappShareButton url={shareUrl}
                                                                 className="mr-5 d-inline-block d-middle">
                                                <WhatsappIcon size={40} round={true}/>
                                            </WhatsappShareButton>
                                            <TelegramShareButton url={shareUrl}
                                                                 className="mr-5 d-inline-block d-middle">
                                                <TelegramIcon size={40} round={true}/>
                                            </TelegramShareButton>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-single">
                                    <div className="row align-items-center">
                                        <div className="col-md-4" style={{}}>
                                            {/*{state.annonceInfo.emplacements_annonce.map((point) => {*/}
                                            {/*    return (*/}
                                            {/*        <div className="card" style={{}} key={point.id}>*/}
                                            {/*            <img*/}
                                            {/*                src={point.image1}*/}
                                            {/*                className="card-img-top"*/}
                                            {/*                onClick={()=>{*/}
                                            {/*                      state.mapInstance.flyTo(*/}
                                            {/*                          [point.latitude, point.longitude],*/}
                                            {/*                          15*/}
                                            {/*                      )*/}
                                            {/*              }}*/}
                                            {/*                style={{width: "100%", height: "150px"}} alt="" />*/}
                                            {/*            <div className="card-body">*/}
                                            {/*              <h5 className="card-title">{point.lieu}</h5>*/}
                                            {/*                <p className={point.annonce}></p>*/}
                                            {/*              <p className="card-text">*/}
                                            {/*                  {point.details}*/}
                                            {/*              </p>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*})}*/}
                                        </div>
                                        {/*<div className="col-md-8" style={{height: "60vh"}}>*/}
                                        {/*      <MapContainer*/}
                                        {/*              center={[5.316667, -4.033333]}*/}
                                        {/*              zoom={7}*/}
                                        {/*              scrollWheelZoom={true}*/}
                                        {/*      >*/}
                                        {/*          <TileLayer*/}
                                        {/*              attribution='@Copyright - @KOLIAC - ENSENBLE AVEC VOUS - CARTOGRAPHIE'*/}
                                        {/*              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"*/}
                                        {/*          />*/}

                                        {/*          <TheMapComponent />*/}

                                        {/*          {state.annonceInfo.emplacements_annonce.map((point) => {*/}
                                        {/*              function IconDisplay() {*/}
                                        {/*                  if (point.type === 'EGLISE') {*/}
                                        {/*                      return EgliseIcon;*/}
                                        {/*                  } else if (point.type === "CIMETIERE") {*/}
                                        {/*                      return CimetiereIcon;*/}
                                        {/*                  } else if (point.type === "DOMICILE") {*/}
                                        {/*                      return CimetiereIcon;*/}
                                        {/*                  }*/}
                                        {/*                }*/}
                                        {/*              return (*/}
                                        {/*                  <Marker*/}
                                        {/*                      key={point.id}*/}
                                        {/*                      icon = {IconDisplay()}*/}
                                        {/*                      position={[*/}
                                        {/*                          point.latitude,*/}
                                        {/*                          point.longitude*/}
                                        {/*                      ]}*/}
                                        {/*                  >*/}
                                        {/*                    <Popup>*/}
                                        {/*                      <div className="card mt-3 mb-3" style={{width: "18rem"}}>*/}
                                        {/*                        <img src={point.image1} className="card-img-top" style={{width: "100%", height: "150px"}} alt="" />*/}
                                        {/*                        <div className="card-body">*/}
                                        {/*                          <h5 className="card-title">{point.lieu}</h5>*/}
                                        {/*                          <p className="card-text">{point.details}</p>*/}
                                        {/*                        </div>*/}
                                        {/*                      </div>*/}
                                        {/*                    </Popup>*/}
                                        {/*                  </Marker>*/}
                                        {/*              )*/}
                                        {/*          })}*/}
                                        {/*      </MapContainer>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
                                <div className="sidebar-border">
                                    <h6 className="f-18">Messages</h6>
                                    <div className="sidebar-list-job">
                                        <ul>
                                            {state.annonceInfo.messages_annonce.map((message) => {
                                                return (
                                                    <li key={message.id}>
                                                        <div
                                                            className="card-list-4 wow animate__animated animate__fadeIn hover-up">
                                                            <div className="image">
                                                                <a href="job-details.html">
                                                                    {message.user.image ? 
                                                                        <img src={message.user.image}
                                                                            style={{width: '25px', height: '25px'}}
                                                                        alt="jobBox"/> :
                                                                        <img src="../assets/imgs/page/homepage1/human.svg" alt="jobBox" />
                                                                    }
                                                                </a>
                                                            </div>
                                                            <div className="info-text">
                                                                <h5 className="font-md font-bold color-brand-1"><a
                                                                    href="#">{message.user.nom}</a></h5>
                                                                <div className="mt-0">
                                                  <span className="card-time">
                                                      {/*<span>{moment(message.created_le).format('DD/MM/YYYY').fromNow(true)}</span>*/} 
                                                      <span>{moment(message.created_le).fromNow(true)}</span>
                                                      {/*<span>{message.created_le}</span>*/}
                                                  </span>
                                                                </div>
                                                                <div className="mt-5">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <h6 className="card-price">
                                                                                {message.contenu}
                                                                            </h6>
                                                                        </div>
                                                                        {/*<div className="col-6 text-end"><span className="card-briefcase">New York, US</span></div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*<div className="modal fade" id="ModalDonMessageForm"*/}
                                                        {/*     tabIndex="-1"*/}
                                                        {/*     aria-hidden="true">*/}
                                                        {/*    <div className="modal-dialog modal-lg">*/}
                                                        {/*        <div className="modal-content apply-job-form">*/}
                                                        {/*            <button className="btn-close" type="button"*/}
                                                        {/*                    data-bs-dismiss="modal"*/}
                                                        {/*                    aria-label="Close"></button>*/}
                                                        {/*            <div className="modal-body pl-30 pr-30 pt-50">*/}
                                                        {/*                <div className="text-center">*/}
                                                        {/*                    <p className="font-sm text-brand-2">Formulaire*/}
                                                        {/*                        Message </p>*/}
                                                        {/*                </div>*/}
                                                        {/*                <form*/}
                                                        {/*                    className="login-register text-start mt-20 pb-30"*/}
                                                        {/*                    action="#">*/}
                                                        {/*                    <div className="form-group">*/}
                                                        {/*                        <label className="form-label"*/}
                                                        {/*                               htmlFor="input-1">Message*/}
                                                        {/*                            *</label>*/}
                                                        {/*                        <textarea*/}
                                                        {/*                            className="form-control"*/}
                                                        {/*                            name="contenu"*/}
                                                        {/*                            rows="8"*/}
                                                        {/*                        >*/}
                                                        {/*                        </textarea>*/}
                                                        {/*                    </div>*/}
                                                        {/*                    <div className="form-group">*/}
                                                        {/*                        <button onClick={submitMessage} className="btn btn-default hover-up w-100" type="submit">*/}
                                                        {/*                            Envoyé*/}
                                                        {/*                        </button>*/}
                                                        {/*                    </div>*/}
                                                        {/*                </form>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="ModalDonMessageForm" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content apply-job-form">
                                <button className="btn-close" type="button"
                                        data-bs-dismiss="modal" aria-label="Close"></button>
                                <div className="modal-body pl-30 pr-30 pt-50">
                                    <div className="text-center">
                                        <p className="font-sm text-brand-2">Formulaire Message </p>
                                    </div>
                                    <form className="login-register text-start mt-20 pb-30"
                                          action="#">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="input-1">Message *</label>
                                            <textarea
                                                style={{padding: "30px", borderRadius: "15px", height: "130px"}}
                                                className="form-control"
                                                placeholder="Message"
                                                id="contenu"
                                                name="contenu"
                                                rows="10"
                                                onChange={handleAddMessage}
                                                value={messageData.contenu}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button onClick={submitMessage} className="btn btn-default hover-up w-100"
                                                    type="submit" name="login">Envoyé
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    )
}

export default DetailAnnonce;