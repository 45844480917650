import React, {Fragment, useContext, useEffect, useState} from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import BaseUrl from "./config/baseUrl";
import StateContext from "../Context/StateContext";
import Validation from "./Validation";
import moment from "moment/moment";
import Header from "./_partials/Header";
import Form from "react-bootstrap/Form";

import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
registerLocale("fr", fr); // register it with the name you want

const url = BaseUrl();

function AddAnnonncePrivilege() {
    const GlobalState = useContext(StateContext)
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [errors, setErrorM] = useState({});

    const [annonceData, setAnnonceData] = useState( {
        // 'id': "",
        'user': "",
        // 'code': "",
        'type_annonce': "",
        'categorie': "",
        'titre': "",
        'date': "",
        'image1': "",
        'image2': "",
        'image3': "",
        'image4': "",
        'image5': "",
        'faire_part': "",
        'programme': "",
        // 'qr_code': "",
        // 'add_le': "",
        // 'date_formatted': "",
        // 'messages_annonce': "",
        // 'emplacements_annonce': "",
    });

    const [tabOne,setTabOne] = useState(1);
    const [tabTwo,setTabTwo] = useState(2);
    const [tabThree,setTabThree] = useState(3);
    const [tabNumber,setTabNumber] = useState(tabOne);

    const CatOption = [
        {
            value: "",
            label: "",
        },
        {
            value: "DECES",
            label: "DECES",
        },
        {
            value: "IN MEMORIAM",
            label: "IN MEMORIAM",
        },
    ]

    const TypeAnnonce  = [
        {
            value: "",
            label: "",
        },
        // {
        //     value: "PREMIUM",
        //     label: "PREMIUM",
        // },
        {
            value: "PRIVILEGE",
            label: "PRIVILEGE",
        },
        // {
        //     value: "GRATUITE",
        //     label: "GRATUITE",
        // },
    ]

    const style = {"backgroundColor":"greenyellow","color":"white","border":"greenyellow"}

    const onclickTabTwo=()=>{
        setErrorM(Validation(annonceData));

        if(annonceData.type_annonce !="" && annonceData.categorie !="" && annonceData.titre !="" && annonceData.date !="")
        {
            setTabNumber(tabTwo);
        }

    }

    const onclickTabThree=()=>{
        setErrorM(Validation(annonceData));
        if(annonceData.faire_part !="" && annonceData.programme !="")
        {
            setTabNumber(tabThree);
        }

    }

    const onClickPrevouis=()=>{
        if(tabNumber != 1) {
            setTabNumber(tabNumber - 1);
        }
    }

    const handleChange=(event)=>{
        setAnnonceData({
            ...annonceData,
            [event.target.name] : event.target.value
        })
    }

    const handleFileChangePhotoAnnonce=(event)=>{
        setAnnonceData({
            ...annonceData,
            [event.target.name]:event.target.files[0]
        });
      }

    const validateCreationAnnonce = () => {
        const _formData = new FormData();
        _formData.append('type_annonce',annonceData.type_annonce);
        _formData.append('categorie',annonceData.categorie);
        _formData.append('titre',annonceData.titre);
        _formData.append('titre',annonceData.titre);
        _formData.append('date',annonceData.date);
        // _formData.append('date', moment(startDate).utc().format('YYYY-MM-DD'));
        _formData.append('faire_part',annonceData.faire_part);
        _formData.append('programme',annonceData.programme);
        _formData.append('image1',annonceData.image1);
        _formData.append('image2',annonceData.image2);
        _formData.append('image3',annonceData.image3);
        _formData.append('image4',annonceData.image4);
        _formData.append('image5',annonceData.image5);
        _formData.append('user', GlobalState.userId);
        console.log(_formData);
        Swal.fire({
            title: 'Enregistrement...',
            html: 'Veillez patientez...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            },
        });
        try {
            axios.post(url+"/annonces/add/", _formData).then((resp) => {
                if(resp.data.bool)
                {
                    Swal.fire({
                      title: 'FELICITATION !',
                      html: "Annonce : <b>"+ annonceData.titre+"</b> enregistrée avec Succès",
                      icon: 'success',
                      showCancelButton: false,
                      confirmButtonColor: '#3085d6',
                      confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/home");
                            //window.location.reload();
                        }
                      });
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    // function FormSubmit (e) {
    //     e.preventDefault();
    //     console.log("formulaire transmis !!!")
    //     dispatch({type: 'changeSendRequest'})
    // }

    return (
        <Fragment>
            <Header />
            <div className="row">
                <div className="col-lg-8 offset-2">
                    <section className="box-content mt-50">
                        <div className="panel-info mb-5">
                            <div className="panel-header bg-100 pt-3 pb-2 border-bottom-0">
                                <ul className="nav justify-content-between nav-wizard" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className={tabNumber == 1 ? "nav-link active fw-semi-bold" : "nav-link fw-semi-bold disabled"}
                                           href={`#bootstrap-wizard-tab${tabOne}`} data-bs-toggle="tab"
                                           aria-selected="true"
                                           role="tab">
                                            <div className="text-center d-inline-block">
                                                <span className="nav-item-circle-parent">
                                <span className="nav-item-circle" style={tabNumber == 2 | tabNumber == 3 ? style : {}}>
                                    <svg className="svg-inline--fa fa-lock" aria-hidden="true" focusable="false"
                                         data-prefix="fas" data-icon="lock" role="img"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                              d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z"></path>
                                    </svg>
                                </span>
                            </span>
                                                <span className="d-none d-md-block mt-1 fs--1">Info Annonce</span>
                                            </div>
                                        </a>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <a className={tabNumber == 2 ? "nav-link active fw-semi-bold" : "nav-link fw-semi-bold disabled"}
                                           href={`#bootstrap-wizard-tab${tabTwo}`} data-bs-toggle="tab"
                                           aria-selected="false"
                                           tabIndex="-1" role="tab">
                                            <div className="text-center d-inline-block ">
                                                <span className="nav-item-circle-parent">
                                <span className="nav-item-circle" style={tabNumber == 3 ? style : {}}>
                                    <svg className="svg-inline--fa fa-file-lines" aria-hidden="true" focusable="false"
                                         data-prefix="fas" data-icon="file-lines" role="img"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path
                                        fill="currentColor"
                                        d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"></path>
                                    </svg>
                                </span>
                            </span>
                                                <span className="d-none d-md-block mt-1 fs--1">Détails Annonces</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className={tabNumber == 3 ? "nav-link active fw-semi-bold" : "nav-link fw-semi-bold disabled"}
                                           href={`#bootstrap-wizard-tab${tabThree}`} data-bs-toggle="tab"
                                           aria-selected="false"
                                           tabIndex="-1" role="tab">
                                            <div className="text-center d-inline-block">
                                                <span className="nav-item-circle-parent">
                                <span className="nav-item-circle">
                                    <svg className="svg-inline--fa fa-check" aria-hidden="true" focusable="false"
                                         data-prefix="fas" data-icon="check" role="img"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                              d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                            </span>
                                                <span className="d-none d-md-block mt-1 fs--1">Validation</span>
                                            </div>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <div className="panel-body pt-4 pb-0">
                                <div className="tab-content">
                                    <div className={tabNumber == 1 ? "tab-pane active" : "tab-pane"} role="tabpanel"
                                         aria-labelledby={`bootstrap-wizard-tab${1}`} id={`bootstrap-wizard-tab${1}`}>
                                        <div className="row form-contact" id="wizardForm1" novalidate="novalidate"
                                             data-wizard-form="1">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="font-sm color-text mb-10">Type Annonce <span
                                                            className="text-danger">*</span></label>
                                                        <Form.Select
                                                            className="form-control"
                                                            style={{padding: "10px", borderRadius: "15px"}}
                                                            aria-label=""
                                                            name="type_annonce"
                                                            value={annonceData.type_annonce}
                                                            onChange={handleChange}
                                                        >
                                                            {TypeAnnonce.map((option) => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                        {errors.countrie &&
                                                            <span className="text-danger">{errors.countrie}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="font-sm color-text mb-10">Catégorie
                                                            Annonce<span className="text-danger">*</span></label>
                                                        <Form.Select
                                                            style={{padding: "10px", borderRadius: "15px"}}
                                                            aria-label=""
                                                            name="categorie"
                                                            value={annonceData.categorie}
                                                            onChange={handleChange}
                                                        >
                                                            {CatOption.map((option) => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="font-sm color-text mb-10">Titre Annonce<span
                                                    className="text-danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    style={{padding: "10px", borderRadius: "15px"}}
                                                    type="text"
                                                    name="titre"
                                                    onChange={handleChange} value={annonceData.titre}
                                                />
                                                {errors.titre && <span className="text-danger">{errors.titre}</span>}
                                            </div>
                                            <div className="form-group">
                                                <label className="font-sm color-text mb-10">Titre Annonce<span
                                                    className="text-danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    style={{padding: "10px", borderRadius: "15px"}}
                                                    type="date"
                                                    name="date"
                                                    id="bootstrap-wizard-wizard-password"
                                                    // selected={startDate}
                                                    // onChange={(date) => setStartDate(date)}
                                                    onChange={handleChange}
                                                    value={annonceData.date}
                                                    // dateFormat="yyyy-MM-dd"
                                                    // maxDate={startDate}
                                                />
                                                {errors.date && <span className="text-danger">{errors.date}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={tabNumber == 2 ? "tab-pane active" : "tab-pane"} role="tabpanel"
                                         aria-labelledby={`bootstrap-wizard-tab${2}`} id={`bootstrap-wizard-tab${2}`}>
                                        <div className="mb-2" id="wizardForm3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="programme">Faire-part <span
                                                    className="text-danger">*</span></label>
                                                <textarea
                                                    style={{padding: "30px", borderRadius: "15px", height: "130px"}}
                                                    className="form-control"
                                                    name="faire_part"
                                                    placeholder="Faire-part"
                                                    id="faire_part"
                                                    rows="8"
                                                    value={annonceData.faire_part}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="programme">Programme <span
                                                    className="text-danger">*</span></label>
                                                <textarea
                                                    style={{padding: "30px", borderRadius: "15px", height: "130px"}}
                                                    className="form-control"
                                                    placeholder="Programme"
                                                    name="programme"
                                                    id="programme"
                                                    rows="8"
                                                    value={annonceData.programme}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="formFileMultiple" className="font-sm color-text mb-10">Image 1<span
                                                    className="text-danger">*</span></label>
                                                <div className="add-file-upload">
                                                    <input
                                                        className="fileupload"
                                                        className="form-control"
                                                        style={{padding: "10px", borderRadius: "15px"}}
                                                        type="file"
                                                        name="image1"
                                                        accept='image/png, image/gif, image/jpeg'
                                                        onChange={handleFileChangePhotoAnnonce}
                                                        // value={annonceData.image1}
                                                    />
                                                </div>
                                                {/*<input className="form-control" type="text" style={{padding: "10px", borderRadius: "15px"}} value="123456789" />*/}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="formFileMultiple" className="font-sm color-text mb-10">Image 2<span className="text-danger">*</span></label>
                                                        <div className="add-file-upload">
                                                            <input
                                                                className="fileupload"
                                                                className="form-control"
                                                                style={{padding: "10px", borderRadius: "15px"}}
                                                                type="file"
                                                                name="image2"
                                                                accept='image/png, image/gif, image/jpeg'
                                                                onChange={handleFileChangePhotoAnnonce}
                                                                // value={annonceData.image1}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="formFileMultiple" className="font-sm color-text mb-10">Image 3<span className="text-danger">*</span></label>
                                                    <div className="add-file-upload">
                                                        <input
                                                            className="fileupload"
                                                            className="form-control"
                                                            style={{padding: "10px", borderRadius: "15px"}}
                                                            type="file"
                                                            name="image3"
                                                            accept='image/png, image/gif, image/jpeg'
                                                            onChange={handleFileChangePhotoAnnonce}
                                                            // value={annonceData.image1}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className="row">*/}
                                            {/*    <div className="col-lg-6">*/}
                                            {/*        <div className="form-group">*/}
                                            {/*            <label htmlFor="formFileMultiple" className="font-sm color-text mb-10">Image 4<span className="text-danger">*</span></label>*/}
                                            {/*            <div className="add-file-upload">*/}
                                            {/*                <input*/}
                                            {/*                    className="fileupload"*/}
                                            {/*                    className="form-control"*/}
                                            {/*                    style={{padding: "10px", borderRadius: "15px"}}*/}
                                            {/*                    type="file"*/}
                                            {/*                    name="image4"*/}
                                            {/*                    accept='image/png, image/gif, image/jpeg'*/}
                                            {/*                    onChange={handleFileChangePhotoAnnonce}*/}
                                            {/*                    // value={annonceData.image1}*/}
                                            {/*                />*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="col-lg-6">*/}
                                            {/*        <label htmlFor="formFileMultiple" className="font-sm color-text mb-10">Image 5<span className="text-danger">*</span></label>*/}
                                            {/*        <div className="add-file-upload">*/}
                                            {/*            <input*/}
                                            {/*                className="fileupload"*/}
                                            {/*                className="form-control"*/}
                                            {/*                style={{padding: "10px", borderRadius: "15px"}}*/}
                                            {/*                type="file"*/}
                                            {/*                name="image5"*/}
                                            {/*                accept='image/png, image/gif, image/jpeg'*/}
                                            {/*                onChange={handleFileChangePhotoAnnonce}*/}
                                            {/*                // value={annonceData.image1}*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="form-group">*/}
                                            {/*    <label htmlFor="formFileMultiple" className="form-label">AJOUTER*/}
                                            {/*        FAIRE-PART(MAX*/}
                                            {/*        5 IMAGES)</label>*/}
                                            {/*    <input*/}
                                            {/*        style={{borderRadius: "15px"}}*/}
                                            {/*        type='file'*/}
                                            {/*        multiple*/}
                                            {/*        accept='image/png, image/gif, image/jpeg'*/}
                                            {/*        onChange={handleChange}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                            {/*<div className="form-control mb-10">*/}
                                            {/*    <ul>*/}
                                            {/*        {annonceData.image1 ? <li>{annonceData.image1}</li> : ""}*/}
                                            {/*        /!*{annonceData.image2 ? <li>{annonceData.image2}</li> : ""}*!/*/}
                                            {/*        /!*{annonceData.image3 ? <li>{annonceData.image3}</li> : ""}*!/*/}
                                            {/*        /!*{annonceData.image4 ? <li>{annonceData.image4}</li> : ""}*!/*/}
                                            {/*        /!*{annonceData.image5 ? <li>{annonceData.image5}</li> : ""}*!/*/}
                                            {/*    </ul>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className={tabNumber == 3 ? "tab-pane active" : "tab-pane"} role="tabpanel"
                                         aria-labelledby={`bootstrap-wizard-tab${tabThree}`}
                                         id={`bootstrap-wizard-tab${tabThree}`}>
                                        <div className="row flex-center pb-8 pt-4 gx-3 gy-4">
                                            <div className="col-12 col-sm-auto">
                                                <div className="text-center">
                                                    <center>
                                                        <h5 className="mb-3">TERMINER !</h5>
                                                        <p className="text-center text-capitalize">Vous pouvez
                                                            maintenant
                                                            valider la creation de Votre
                                                            Annonce<b> {annonceData.titre}</b><br/></p>
                                                        <button className="btn btn-primary px-6" href="wizard.html" onClick={validateCreationAnnonce}>Valider
                                                        </button>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-footer border-top-0">
                                <div className="d-flex pager wizard list-inline mb-0">
                                    {tabNumber != 1 &&
                                        <button className="btn btn-link ps-0" type="button" onClick={onClickPrevouis}>
                                            <svg className="svg-inline--fa fa-chevron-left me-1"
                                                 data-fa-transform="shrink-3"
                                                 aria-hidden="true" focusable="false" data-prefix="fas"
                                                 data-icon="chevron-left"
                                                 role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                                 data-fa-i2svg="" style={{"transformOrigin": "0.3125em 0.5em"}}>

                                                <g transform="translate(160 256)">
                                                    <g transform="translate(0, 0)  scale(0.8125, 0.8125)  rotate(0 0 0)">
                                                        <path fill="currentColor"
                                                              d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"
                                                              transform="translate(-160 -256)"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                            Précédent
                                        </button>
                                    }

                                    {tabNumber == 1 &&
                                        <div className="flex-1 text-end">
                                            <button className="btn btn-primary px-6 px-sm-6" type="button"
                                                    onClick={onclickTabTwo}>Suivant
                                                <svg className="svg-inline--fa fa-chevron-right ms-1"
                                                     data-fa-transform="shrink-3" aria-hidden="true" focusable="false"
                                                     data-prefix="fas" data-icon="chevron-right" role="img"
                                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                                     data-fa-i2svg=""
                                                     style={{"transformOrigin": "0.3125em 0.5em"}}>
                                                    <g transform="translate(160 256)">
                                                        <g transform="translate(0, 0)  scale(0.8125, 0.8125)  rotate(0 0 0)">
                                                            <path fill="currentColor"
                                                                  d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                                                  transform="translate(-160 -256)">
                                                            </path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    }

                                    {tabNumber == 2 &&
                                        <div className="flex-1 text-end">
                                            <button className="btn btn-primary px-6 px-sm-6" type="button"
                                                    onClick={onclickTabThree}>Suivant
                                                <svg className="svg-inline--fa fa-chevron-right ms-1"
                                                     data-fa-transform="shrink-3" aria-hidden="true" focusable="false"
                                                     data-prefix="fas" data-icon="chevron-right" role="img"
                                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                                     data-fa-i2svg=""
                                                     style={{"transformOrigin": "0.3125em 0.5em"}}>
                                                    <g transform="translate(160 256)">
                                                        <g transform="translate(0, 0)  scale(0.8125, 0.8125)  rotate(0 0 0)">
                                                            <path fill="currentColor"
                                                                  d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                                                                  transform="translate(-160 -256)">
                                                            </path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </Fragment>
    )

}

export default AddAnnonncePrivilege;